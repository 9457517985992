<template>
  <div v-if="advertorial" class="container base-content-container">
    <ConfirmModal ref="confirmModal" />
    <div class="grid-row">
      <div class="col-8">
        <h1 class="headline headline--main mt--8">{{ advertorial.title }}</h1>
        <TabNav />
      </div>
      <div class="col-12">
        <ArticlePreview
          :desktopUrl="advertorial?.desktopUrl"
          :mobileUrl="advertorial?.mobileUrl"
          :desktopLiveUrl="advertorial?.desktopLiveUrl"
        >
          <template v-slot:shareButton>
            <ShareButton
              @sharedClicked="linkShared"
              :advertorialIsShared="advertorial?.shared"
              :shareUrl="advertorial?.shareUrl"
            />
          </template>
        </ArticlePreview>
      </div>
      <div class="col-8">
        <div v-if="showReleaseButton" class="d--flex mt--10">
          <BaseButton
            :text="'Artikel freigeben'"
            icon="arrow-right"
            iconPosition="right"
            class="button--inline ml--auto"
            @click="onArticleReleaseClick"
          />
        </div>
        <div v-else-if="showSubmitArticleButton" class="d--flex mt--10">
          <BaseButton
            :text="'Artikel einreichen'"
            icon="arrow-right"
            iconPosition="right"
            class="button--inline ml--auto"
            @click="onArticleSubmitClick"
          />
        </div>

        <Feedback
          :advertorialId="advertorialId"
          :advertorialStatus="advertorial?.advertorialStatus"
          :showFinalizeFeedbackButton="showFinalizeFeedbackButton"
          @textareaFocussed="statusBoxIsOpen = true"
        />
      </div>
    </div>
    <div class="status-box-scroll-container container grid-row">
      <StatusBox
        :accordionIsInitiallyOpen="statusBoxIsOpen"
        class="col-4 col-start-9 mt--4"
      />
    </div>
  </div>
</template>

<script>
import advertorialMixin from "@/mixins/advertorialMixin";
import TabNav from "@/components/advertorial/TabNav";
import StatusBox from "@/components/advertorial/StatusBox";
import ArticlePreview from "@/components/advertorial/ArticlePreview";
import ShareButton from "@/components/advertorial/ShareButton";
import { mapActions } from "vuex";
import BaseButton from "@/components/elements/BaseButton";
import Feedback from "@/components/advertorial/Feedback";
import ConfirmModal from "@/components/common/ConfirmModal";

export default {
  name: "PreviewAndFeedback",
  components: {
    ConfirmModal,
    Feedback,
    BaseButton,
    ArticlePreview,
    TabNav,
    ShareButton,
    StatusBox,
  },
  mixins: [advertorialMixin],
  data() {
    return {
      statusBoxIsOpen: false,
    };
  },
  methods: {
    ...mapActions("advertorial", ["approveAdvertorial", "submitAdvertorial"]),
    async onArticleReleaseClick() {
      if (
        await this.$refs.confirmModal.show({
          titleText: "Sind Sie sicher?",
          mainText: ` Nachdem Sie das Advertorial freigegeben haben, wird dieses automatisch gestartet. Änderungen am Text sind dann nicht mehr möglich.`,
          cancelText: "Abbrechen",
          confirmText: "Freigeben",
        })
      ) {
        await this.approveAdvertorial(this.advertorialId);
        await this.fetchAdvertorial(this.advertorialId);
      }
    },
    async onArticleSubmitClick() {
      const result = await this.submitAdvertorial(this.advertorialId);
      await this.fetchAdvertorial(this.advertorialId);
      if (result) {
        this.$refs.confirmModal.show({
          titleText: "Artikel wurde erfolgreich eingereicht!",
          mainText:
            "Unser Content-Team überprüft nun Ihr Advertorial und meldet sich zeitnah mit Feedback zurück oder gibt den Artikel umgehend frei.",
        });
      }
    },
    async linkShared(value) {
      this.updateAdvertorial({
        advertorialId: this.advertorialId,
        prop: "shared",
        value: value,
      });
      await this.patchAdvertorial(this.advertorialId);
    },
  },
};
</script>
<style scoped lang="stylus">
.base-content-container {
  position relative
}

.status-box-scroll-container {
  position absolute
  top 0
  left 0
  width 100%
  height 100%
  align-items flex-start
  pointer-events none

  > * {
    pointer-events auto
  }
}
</style>
