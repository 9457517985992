<template>
  <section class="article-slot">
    <ArticleSlotHeader
      :slot-type="slotType"
      :slot-short-key="slotShortKey"
      :slot-index="slotIndex"
      :slot-length="article.slots?.length ?? 0"
      :component-labels="componentLabels"
      :editable="editable"
      @delete-slot="onDeleteClick"
      @shift-slot="onShiftSlotClick"
    />
    <main class="article-slot__main">
      <BaseTextInput
        v-model="title"
        :label="componentLabels?.title?.label"
        :readonly="!editable"
        class="form-field--small mt--3"
      />
      <div v-if="links.length" class="grid-row mt--6">
        <div class="col-6 d--flex">
          {{ componentLabels?.url?.label }}
          <div class="form-field__required">(Pflicht)</div>
        </div>
        <div class="col-6 d--flex">
          {{ componentLabels?.linkText?.label }}
          <div class="form-field__required">(Pflicht)</div>
        </div>
      </div>
      <ArticleLinkFieldGroup
        v-for="(link, linkIndex) in links"
        :key="`link-field-group-${linkIndex}`"
        :modelValue="link"
        :linkIndex="linkIndex"
        :component-labels="componentLabels"
        :class="linkIndex ? 'mt--3' : 'mt--1'"
        :editable="editable"
        @update:modelValue="(val) => updateLinkAtIndex(linkIndex, val)"
        @removeLinkAtIndex="onRemoveLinkAtIndex(linkIndex)"
      />
      <BaseButton
        v-if="editable"
        :text="componentLabels?.buttonText"
        icon="plus"
        class="button--inline button--black button--small mt--3"
        @click="onAddLinkClick"
      />
    </main>
  </section>
</template>
<script>
import BaseTextInput from "@/components/form/BaseTextInput.vue";
import ArticleSlotHeader from "@/components/article/ArticleSlotHeader.vue";
import articleSlotMixin from "@/mixins/articleSlotMixin";
import ArticleLinkFieldGroup from "@/components/article/ArticleLinkFieldGroup.vue";
import BaseButton from "@/components/elements/BaseButton.vue";

export default {
  name: "ArticleLinkBoxSlot",
  components: {
    BaseButton,
    ArticleLinkFieldGroup,
    ArticleSlotHeader,
    BaseTextInput,
  },
  mixins: [articleSlotMixin],
  computed: {
    title: {
      get() {
        return this.article?.slots[this.slotIndex]?.title;
      },
      set(value) {
        this.updateStore("title", value);
      },
    },
    links() {
      return (
        this.article?.slots[this.slotIndex]?.links || [
          {
            url: null,
            linkText: null,
          },
        ]
      );
    },
  },
  methods: {
    onAddLinkClick() {
      const links = [
        ...this.links,
        {
          url: null,
          linkText: null,
        },
      ];
      this.updateStore("links", links);
    },
    onRemoveLinkAtIndex(indexToRemove) {
      const links = [...this.links];
      links.splice(indexToRemove, 1);
      this.updateStore("links", links);
    },
    updateLinkAtIndex(indexToUpdate, value) {
      const links = [...this.links];
      links[indexToUpdate] = value;
      this.updateStore("links", links);
    },
  },
};
</script>
