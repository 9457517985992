<template>
  <section class="mt--2 briefing-note">
    <BaseButton
      class="button--inline button--black button--medium"
      :text="labels.button"
      @click="$refs.modal.open()"
    />
    <modal ref="modal" @click="outsideClick">
      <div class="modal-wrapper">
        <button @click="$refs.modal.close()" class="button-close">
          <svg-icon icon="times" class="icon-close" />
        </button>
        <div class="richtext">
          <h2 class="note-headline">{{ labels.headline }}</h2>
          <p v-html="text"></p>
        </div>
      </div>
    </modal>
  </section>
</template>

<script>
import BaseButton from "@/components/elements/BaseButton";
import Modal from "@/components/common/Modal";
import SvgIcon from "@/components/common/SvgIcon";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "BriefingNote",
  components: { Modal, BaseButton, SvgIcon },
  props: {
    labels: {
      type: Object,
    },
    text: {
      type: String,
    },
  },
  computed: {
    ...mapGetters("common", {
      hasBriefing: "getHasBriefing",
    }),
  },
  methods: {
    ...mapActions("common", ["updateHasBriefing"]),
    outsideClick() {
      if (event.target == document.querySelector(".modal--backdrop")) {
        this.$refs.modal.close();
      }
    },
  },
  mounted() {
    if (!this.hasBriefing) {
      this.$refs.modal.open();
      this.updateHasBriefing();
    }
  },
};
</script>

<style scoped>
.briefing-note {
  display: flex;
  justify-content: flex-end;
}
.modal-wrapper {
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: #ffffff;
  width: 70%;
  gap: 30px;
  max-height: 70vh;
  border-radius: 8px;
  padding: 24px 4px 24px 24px;
}
.richtext {
  padding: 0 30px;
  overflow-y: auto;
}
.richtext::-webkit-scrollbar {
  background-color: transparent;
  width: 3px;
}
.richtext::-webkit-scrollbar-thumb {
  background: #666666 !important;
  border-radius: 8px;
  width: 6px;
}
.button-close {
  position: absolute;
  right: 15px;
  top: 10px;
  width: 20px;
  height: 20px;
  margin-left: auto;
  transition: color 0.2s ease-in-out;
  cursor: pointer;
}
.note-headline {
  margin-bottom: 16px;
}
</style>
