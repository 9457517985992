<template>
  <section class="article-slot">
    <ArticleSlotHeader
      :slot-type="slotType"
      :slot-short-key="slotShortKey"
      :component-labels="componentLabels"
      :slot-length="article.slots?.length ?? 0"
      :slot-index="slotIndex"
      :editable="editable"
      @delete-slot="onDeleteClick"
      @shift-slot="onShiftSlotClick"
    />
    <main class="article-slot__main">
      <BaseTextInput
        v-model="v$.youtubeVideoUrl.$model"
        :label="componentLabels?.youtubeVideoUrl?.label"
        :validator="v$.youtubeVideoUrl"
        :readonly="!editable"
        :is-required="editable"
        class="form-field--small mt--3"
      />
    </main>
  </section>
</template>
<script>
import BaseTextInput from "@/components/form/BaseTextInput.vue";
import { required, url } from "@vuelidate/validators";
import ArticleSlotHeader from "@/components/article/ArticleSlotHeader.vue";
import articleSlotMixin from "@/mixins/articleSlotMixin";
import useVuelidate from "@vuelidate/core";

export default {
  name: "ArticleYoutubeVideoSlot",
  components: { ArticleSlotHeader, BaseTextInput },
  mixins: [articleSlotMixin],
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  computed: {
    youtubeVideoUrl: {
      get() {
        return this.article?.slots[this.slotIndex]?.youtubeVideoUrl;
      },
      set(value) {
        this.updateStore("youtubeVideoUrl", value);
      },
    },
  },
  validations() {
    return {
      youtubeVideoUrl: {
        required,
        url,
      },
    };
  },
};
</script>
