<template>
  <section class="article-slot">
    <ArticleSlotHeader
      :slot-type="slotType"
      :slot-short-key="slotShortKey"
      :slot-index="slotIndex"
      :slot-length="article.slots?.length ?? 0"
      :component-labels="componentLabels"
      :editable="editable"
      @delete-slot="onDeleteClick"
      @shift-slot="onShiftSlotClick"
    />
    <main class="article-slot__main">
      <BaseTextInput
        v-model="title"
        :label="componentLabels?.title?.label"
        :readonly="!editable"
        class="form-field--small mt--3"
      />
      <div class="mt--6">
        <p>
          {{ componentLabels?.text?.label }}
        </p>
        <BaseTextEditor
          v-model="text"
          :extended-marks="true"
          :readonly="!editable"
          class="mt--1"
        />
      </div>
    </main>
  </section>
</template>
<script>
import BaseTextInput from "@/components/form/BaseTextInput.vue";
import ArticleSlotHeader from "@/components/article/ArticleSlotHeader.vue";
import articleSlotMixin from "@/mixins/articleSlotMixin";
import BaseTextEditor from "@/components/form/BaseTextEditor.vue";

export default {
  name: "ArticleParagraphSlot",
  components: { BaseTextEditor, ArticleSlotHeader, BaseTextInput },
  mixins: [articleSlotMixin],
  computed: {
    title: {
      get() {
        return this.article?.slots[this.slotIndex]?.title;
      },
      set(value) {
        this.updateStore("title", value);
      },
    },
    text: {
      get() {
        return this.article?.slots[this.slotIndex]?.text;
      },
      set(value) {
        this.updateStore("text", value);
      },
    },
  },
};
</script>
