<template>
  <transition name="fade">
    <div class="modal--backdrop" v-if="isVisible">
      <slot></slot>
    </div>
  </transition>
</template>

<script>
export default {
  name: "Modal",

  data() {
    return {
      isVisible: false,
    };
  },
  methods: {
    open() {
      this.isVisible = true;
      const html = document.querySelector("html");
      const scrollBarWidth = window.innerWidth - html.clientWidth + "px";
      html.style.paddingRight = scrollBarWidth;
      html.style.overflow = "hidden";
    },

    close() {
      this.isVisible = false;
      const html = document.querySelector("html");
      html.style.paddingRight = null;
      html.style.overflow = null;
    },
  },
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease-in-out;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
.modal--backdrop {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0.5rem;
  z-index: 10;
  pointer-events: all;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
