<template>
  <section class="article-slot">
    <ArticleSlotHeader
      :slot-type="slotType"
      :slot-short-key="slotShortKey"
      :slot-index="slotIndex"
      :slot-length="article.slots?.length ?? 0"
      :component-labels="componentLabels"
      :editable="editable"
      @delete-slot="onDeleteClick"
      @shift-slot="onShiftSlotClick"
    />
    <main class="article-slot__main">
      <BaseTextInput
        v-model="title"
        :label="componentLabels?.title?.label"
        :readonly="!editable"
        class="form-field--small mt--3"
      />
      <ArticleImage
        :medium="medium"
        :editable="editable"
        class="mt--4"
        @medium-selected="(val) => updateStore('medium', val)"
      />
    </main>
  </section>
</template>
<script>
import BaseTextInput from "@/components/form/BaseTextInput.vue";
import ArticleSlotHeader from "@/components/article/ArticleSlotHeader.vue";
import articleSlotMixin from "@/mixins/articleSlotMixin";
import ArticleImage from "@/components/article/ArticleImage.vue";

export default {
  name: "ArticleMediumSlot",
  components: { ArticleImage, ArticleSlotHeader, BaseTextInput },
  mixins: [articleSlotMixin],
  computed: {
    title: {
      get() {
        return this.article?.slots[this.slotIndex]?.title;
      },
      set(value) {
        this.updateStore("title", value);
      },
    },
    medium() {
      return this.article?.slots[this.slotIndex]?.medium;
    },
  },
};
</script>
