<template>
  <div v-if="advertorial" class="container">
    <div class="grid-row">
      <div class="col-8">
        <h1 class="headline headline--main mt--8">{{ advertorial.title }}</h1>
        <TabNav />
        <BriefingNote
          v-if="getBriefingConfig.showBriefingNote"
          :text="getBriefingConfig.briefingNote"
          :labels="componentLabels.note"
        />
        <section :class="{ 'mt--5': !getBriefingConfig.showBriefingNote }">
          <span class="copy">{{ componentLabels.keyword.title }}</span>
          <h2 class="headline headline--main mt--1">
            {{ advertorial.keyword }}
          </h2>
        </section>
        <section class="mt--3">
          <h5 class="headline">
            {{ componentLabels.product.title }}
            <Tooltip>{{ componentLabels.product.tooltip }}</Tooltip>
          </h5>
          <BaseTextarea
            v-model="product"
            :placeHolder="componentLabels.product.placeHolder"
            class="mt--1"
          />
        </section>
        <section class="mt--4">
          <h5 class="headline">
            {{ componentLabels.homepage.title }}
            <Tooltip>{{ componentLabels.homepage.tooltip }}</Tooltip>
          </h5>
          <BaseTextarea
            v-model="homepage"
            :placeHolder="componentLabels.homepage.placeHolder"
            class="mt--1"
          />
        </section>
        <section class="mt--4">
          <h5 class="headline">
            {{ componentLabels.claim.title }}
            <Tooltip>{{ componentLabels.claim.tooltip }}</Tooltip>
          </h5>
          <BaseTextarea
            v-model="claim"
            :placeHolder="componentLabels.claim.placeHolder"
            class="mt--1"
          />
        </section>
        <section class="mt--4">
          <h5 class="headline">
            {{ componentLabels.genderSensitiveSpelling?.title }}
          </h5>
          <BaseTextarea
            v-model="genderSensitiveSpelling"
            :placeHolder="componentLabels.genderSensitiveSpelling?.placeHolder"
            class="mt--1"
          />
        </section>
        <section class="mt--4">
          <h5 class="headline">
            {{ componentLabels.files.title }}
            <Tooltip>{{ componentLabels.files.tooltip }}</Tooltip>
          </h5>
          <BaseFileInput
            v-for="(file, fileIndex) in [
              ...files,
              ...(files.length < maxFilesCount ? [emptyFileObject()] : []),
            ]"
            :data-test="fileIndex"
            :key="`briefing-file-${file?.mediumId || file.tempId}`"
            :existingMedium="file?.mediumId ? file : null"
            :class="file?.file || file?.mediumId ? 'mt--1' : 'mt--2'"
            @update:modelValue="
              (val) => updateFileAtIndex(fileIndex, file, val)
            "
            @removeFile="onRemoveFileAtIndex(fileIndex)"
          />
        </section>
        <section class="mt--4">
          <h5 class="headline">
            {{ componentLabels.links.title }}
            <Tooltip>{{ componentLabels.links.tooltip }}</Tooltip>
          </h5>
          <LinkFieldGroup
            v-for="(link, linkIndex) in links"
            :key="`link-field-group-${linkIndex}`"
            :modelValue="link"
            :linkIndex="linkIndex"
            @update:modelValue="(val) => updateLinkAtIndex(linkIndex, val)"
            @removeLinkAtIndex="onRemoveLinkAtIndex(linkIndex)"
          />
          <BaseButton
            v-if="links.length < maxLinksCount"
            :text="componentLabels?.links.buttonText"
            icon="plus"
            class="button--inline button--black button--medium mt--2"
            @click="onAddLinkLinkClick"
          />
        </section>
        <div class="d--flex mt--10">
          <BaseButton
            :text="labels.common.save"
            icon="save"
            class="button--inline button--black"
            @click="onSaveClick"
          />
          <BaseButton
            :text="componentLabels.continueLink.title"
            icon="arrow-right"
            iconPosition="right"
            class="button--inline ml--auto"
            @click="onContinueClick"
          />
        </div>
        <Feedback
          :advertorialId="advertorialId"
          :advertorialStatus="advertorial?.advertorialStatus"
          :showFinalizeFeedbackButton="showFinalizeFeedbackButton"
        />
      </div>
      <div class="col-4 mt--4">
        <StatusBox />
      </div>
    </div>
  </div>
  <UnsavedChangesModal ref="confirmModal" />
</template>

<script>
import useVuelidate from "@vuelidate/core";
import advertorialMixin from "@/mixins/advertorialMixin";
import TabNav from "@/components/advertorial/TabNav";
import StatusBox from "@/components/advertorial/StatusBox";
import { mapGetters, mapState } from "vuex";
import Tooltip from "@/components/common/Tooltip";
import BaseButton from "@/components/elements/BaseButton";
import BaseTextarea from "@/components/form/BaseTextarea";
import LinkFieldGroup from "@/components/advertorial/LinkFieldGroup";
import BaseFileInput from "@/components/form/BaseFileInput";
import { MEDIUM_PURPOSE_TYPES } from "@/constants";
import UnsavedChangesModal from "@/components/common/UnsavedChangesModal";
import BriefingNote from "@/components/common/BriefingNote";
import Feedback from "@/components/advertorial/Feedback.vue";

export default {
  name: "Briefing",
  components: {
    Feedback,
    BriefingNote,
    UnsavedChangesModal,
    BaseFileInput,
    LinkFieldGroup,
    BaseTextarea,
    BaseButton,
    Tooltip,
    StatusBox,
    TabNav,
  },
  mixins: [advertorialMixin],
  props: {
    maxLinksCount: {
      type: Number,
      default: 3,
    },
    maxFilesCount: {
      type: Number,
      default: 5,
    },
    purposeType: {
      type: String,
      default: MEDIUM_PURPOSE_TYPES.briefing,
    },
  },
  async beforeRouteLeave(to, from, next) {
    const leaveSite = () => {
      this.updateIsDirty({ value: false });
      window.removeEventListener("beforeunload", this.checkIfDirty);
      next();
    };

    if (this.isDirty) {
      const modal = await this.$refs.confirmModal.show({
        titleText: "Ungespeicherte Änderungen",
        mainText: ` Sie haben Änderungen vorgenommen,die bisher nicht gespeichert wurden. Sollen diese gespeichert werden?`,
        cancelText: "Änderungen verwerfen",
        confirmText: "Speichern",
      });
      if (modal !== "interrupt") {
        if (modal) {
          await this.onSaveClick();
          leaveSite();
        } else {
          await this.fetchAdvertorial(this.advertorialId);
          leaveSite();
        }
      } else {
        next(false);
      }
    } else {
      leaveSite();
    }
  },
  created() {
    const bindUnloadEvent = () => {
      window.addEventListener("beforeunload", this.checkIfDirty);
      window.removeEventListener("click", bindUnloadEvent);
    };
    window.addEventListener("click", bindUnloadEvent);
    this.updateIsDirty({ value: false });
  },

  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  computed: {
    claim: {
      get() {
        return this.advertorial?.briefing?.claim;
      },
      set(value) {
        this.updateBriefing("claim", value);
      },
    },
    ...mapGetters("common", ["getBriefingConfig"]),
    ...mapState("common", ["labels"]),
    componentLabels() {
      return this.labels?.advertorial?.briefing || {};
    },
    links() {
      return this.advertorial?.briefing?.links || [null];
    },
    homepage: {
      get() {
        return this.advertorial?.briefing?.homepage;
      },
      set(value) {
        this.updateBriefing("homepage", value);
      },
    },
    genderSensitiveSpelling: {
      get() {
        return this.advertorial?.briefing?.genderSensitiveSpelling;
      },
      set(value) {
        this.updateBriefing("genderSensitiveSpelling", value);
      },
    },
    product: {
      get() {
        return this.advertorial?.briefing?.product;
      },
      set(value) {
        this.updateBriefing("product", value);
      },
    },
  },
  methods: {
    onContinueClick() {
      this.onSaveClick().then(() => {
        this.$router.push(`/advertorial/${this.advertorialId}/medien`);
      });
    },
    onAddLinkLinkClick() {
      const links = [
        ...this.links,
        {
          url: null,
          text: null,
        },
      ];
      this.updateBriefing("links", links);
    },
    onRemoveLinkAtIndex(indexToRemove) {
      const links = [...this.links];
      links.splice(indexToRemove, 1);
      this.updateBriefing("links", links);
    },
    updateLinkAtIndex(indexToUpdate, value) {
      const links = [...this.links];
      links[indexToUpdate] = value;
      this.updateBriefing("links", links);
    },
  },
  validations() {
    return {};
  },
};
</script>
<style lang="stylus">

//Make textarea font-size smaller for Briefing
.form-field{
  &__input{
    font-size $font-size.m
  }
}
</style>
