<template>
  <div
    class="image-input form-field form-field--small article-image"
    :class="[{ 'article-image--error': hasError }]"
  >
    <div class="form-field__wrapper">
      <div class="form-field__label">
        {{ componentLabels?.label }}
        <div v-if="isRequired" class="form-field__required">(Pflicht)</div>
      </div>
      <div class="form-field__input-wrapper">
        <div class="article-image__image-wrapper">
          <img
            v-if="medium?.source"
            :src="medium.source"
            :alt="medium.title"
            class="article-image__preview-image"
          />
          <div
            v-if="editable"
            class="article-image__edit-layer"
            :class="{ '-visible': !medium?.source }"
            @click="this.showModal = true"
          >
            <SvgIcon
              icon="image-inverted"
              class="icon article-image__edit-layer-icon"
            />
            <BaseButton
              :text="componentLabels?.editButtonText"
              icon="file-upload"
              class="
                button--inline button--small button--black
                article-image__edit-layer-button
              "
            />
          </div>
        </div>
        <span v-if="errorMessage" class="form-field__error-message"
          >{{ errorMessage }}
        </span>
      </div>
    </div>
    <BaseDialog :open="showModal" @modal-show-change="onModalShowChange">
      <h3 class="headline d--flex-center">
        {{ componentLabels?.headline }}
        <Tooltip v-if="componentLabels?.tooltip"
          >{{ componentLabels?.tooltip }}
        </Tooltip>
      </h3>
      <div class="mt--2">
        <div v-if="modalContentType === 'select'" class="medium-selection">
          <div class="grid-row">
            <div
              v-for="mediumOption in availableImages"
              :key="`image-select-${mediumOption.mediumId}`"
              :class="availableImages.length > 8 ? 'col-2' : 'col-3'"
            >
              <label
                class="medium-selection__tile"
                :class="{
                  '-disabled':
                    mediumOption.mediumId !== medium.mediumId &&
                    disabledMediumIds.includes(mediumOption.mediumId),
                }"
              >
                <input
                  type="radio"
                  v-model="selectedMediumId"
                  :value="mediumOption.mediumId"
                />
                <img :src="mediumOption.source" :alt="mediumOption.title" />
                <button
                  type="button"
                  class="medium-selection__edit-button"
                  @click="onEditImageClick(mediumOption.mediumId)"
                >
                  <SvgIcon icon="edit" class="d--block" />
                </button>
              </label>
            </div>
          </div>
          <div class="medium-selection__button-wrapper">
            <BaseButton
              :text="componentLabels?.selectButtonText"
              icon="image-inverted"
              class="button--medium"
              :class="{ 'button--disabled': !selectedMediumId }"
              @click="onMediumSelected"
            />
            <BaseButton
              :text="componentLabels?.uploadButtonText"
              icon="upload"
              class="button--medium button--black"
              @click="onAddImageClick"
            />
          </div>
        </div>
        <div v-else-if="modalContentType === 'upload'">
          <MediaFigure
            type="image"
            :display-header="false"
            :display-all-fields="false"
            @update:modelValue="
              (val) =>
                updateFileAtIndex(newImageIndex, files[newImageIndex], val)
            "
            class="mt--5"
          />
          <div class="medium-selection__button-wrapper">
            <BaseButton
              :text="componentLabels?.saveButtonText"
              icon="save"
              class="button--medium"
              @click="onImageSaveClick"
            />
            <BaseButton
              :text="componentLabels?.cancelButtonText"
              icon="times"
              class="button--medium button--red"
              @click="onCancelUploadClick"
            />
          </div>
        </div>
        <div v-else-if="modalContentType === 'edit'">
          <MediaFigure
            type="image"
            :display-header="false"
            :display-all-fields="false"
            :existing-medium="selectedMedium"
            :is-always-editable="editable"
            @update:modelValue="(val) => (unsavedMediumEdits = val)"
            class="mt--5"
          />
          <div class="medium-selection__button-wrapper">
            <BaseButton
              :text="componentLabels?.saveButtonText"
              icon="save"
              class="button--medium"
              @click="onConfirmEditClick"
            />
            <BaseButton
              :text="componentLabels?.cancelButtonText"
              icon="times"
              class="button--medium button--red"
              @click="onCancelEditClick"
            />
          </div>
        </div>
      </div>
    </BaseDialog>
  </div>
</template>
<script>
import BaseButton from "@/components/elements/BaseButton.vue";
import SvgIcon from "@/components/common/SvgIcon.vue";
import BaseDialog from "@/components/common/BaseDialog.vue";
import { MEDIUM_PURPOSE_TYPES } from "@/constants";
import advertorialMixin from "@/mixins/advertorialMixin";
import MediaFigure from "@/components/advertorial/MediaFigure.vue";
import { mapState } from "vuex";
import Tooltip from "@/components/common/Tooltip.vue";
import useVuelidate from "@vuelidate/core";

export default {
  name: "ArticleImage",
  components: { Tooltip, MediaFigure, BaseDialog, SvgIcon, BaseButton },
  mixins: [advertorialMixin],
  props: {
    disabledMediumIds: {
      type: Array,
      default() {
        return [];
      },
    },
    editable: {
      type: Boolean,
      default: true,
    },
    medium: {
      type: Object,
      default() {
        return {};
      },
    },
    validator: {
      type: Object,
      default: null,
    },
    isRequired: {
      type: Boolean,
      default: false,
    },
    purposeType: {
      type: String,
      default: MEDIUM_PURPOSE_TYPES.frontend,
    },
  },
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  data() {
    return {
      showModal: false,
      selectedMediumId: null,
      modalContentType: "select",
      newImageIndex: null,
      unsavedMediumEdits: null,
    };
  },
  computed: {
    availableImages() {
      const files = this.advertorial?.files?.[
        MEDIUM_PURPOSE_TYPES.frontend
      ]?.filter((file) => file.type === "image");
      return files?.length ? files : [];
    },
    ...mapState("common", ["labels"]),
    componentLabels() {
      return this.labels?.advertorial?.article?.imageSelection || {};
    },
    selectedMedium() {
      return this.availableImages.find(
        (image) => image.mediumId === this.selectedMediumId
      );
    },
    selectedMediumIndex() {
      return this.availableImages.findIndex(
        (image) => image.mediumId === this.selectedMediumId
      );
    },
    hasError() {
      return Boolean(this.validator?.$errors?.length);
    },
    errorMessage() {
      if (this.hasError) {
        const validatorName = this.validator?.$errors[0].$validator;
        const defaultMessage = this.validator?.$errors[0].$message;
        return (
          this.labels?.formFields?.errorMessages?.[validatorName] ||
          defaultMessage
        );
      } else {
        return null;
      }
    },
  },
  watch: {
    medium: {
      handler(newValue) {
        this.selectedMediumId = newValue?.mediumId;
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    onAddImageClick() {
      this.newImageIndex = this.files.length;
      this.updateFileAtIndex(this.newImageIndex, this.emptyFileObject("image"));
      this.$nextTick(() => {
        this.modalContentType = "upload";
      });
    },
    onEditImageClick(mediumId) {
      this.selectedMediumId = mediumId;
      this.$nextTick(() => {
        this.modalContentType = "edit";
      });
    },
    async onCancelUploadClick() {
      await this.onRemoveFileAtIndex(this.newImageIndex);
      this.$nextTick(() => {
        this.modalContentType = "select";
        this.newImageIndex = null;
      });
    },
    async onConfirmEditClick() {
      await this.updateFileAtIndex(
        this.selectedMediumIndex,
        this.selectedMedium,
        this.unsavedMediumEdits
      );
      this.onSaveClick().then(() => {
        this.$nextTick(() => {
          this.modalContentType = "select";
        });
      });
    },
    onCancelEditClick() {
      //TODO: Revert possible changes
      this.modalContentType = "select";
    },
    onImageSaveClick() {
      this.onSaveClick().then(() => {
        this.$nextTick(() => {
          this.selectedMediumId = this.files[this.newImageIndex]?.mediumId;
          this.modalContentType = "select";
          this.newImageIndex = null;
        });
      });
    },
    onMediumSelected() {
      const selectedMedium = this.availableImages.find(
        (medium) => medium.mediumId === this.selectedMediumId
      );
      this.$emit("medium-selected", selectedMedium);
      this.$nextTick(() => {
        this.showModal = false;
      });
    },
    async onModalShowChange(show) {
      if (!show && this.newImageIndex) {
        await this.onRemoveFileAtIndex(this.newImageIndex);
        this.modalContentType = "select";
        this.newImageIndex = null;
      }
      this.showModal = show;
    },
  },
  emits: ["medium-selected"],
};
</script>

<style scoped lang="stylus">

.article-image {

  &__image-wrapper {
    aspect-ratio 16 / 9
    background-color $color-lightest-gray
    position relative
  }

  &__preview-image {
    width 100%
    height 100%
    object-fit contain
  }

  &__edit-layer {
    position absolute
    top 0
    left 0
    width 100%
    height 100%
    display flex
    justify-content center
    align-items center
    flex-direction column
    background-color rgba($color-background-form-field, 0.85)
    opacity 0
    cursor pointer
    box-shadow none
    transition box-shadow $duration-transition-hover-default ease-in-out, opacity $duration-transition-hover-default ease-in-out

    &:hover, &:focus {
      box-shadow inset 0 0 0 2px $color-brand-green
    }

    ^[0]--error & {
      box-shadow inset 0 0 0 2px $color-form-error
    }

    &:hover
    &.-visible {
      opacity 1
    }

    &.-visible {
      background-color transparent
    }

    &-icon {
      font-size 48px
    }

    &-note {
      margin-top 8px
      font-size $font-size.m
      font-weight $font-weight-bold
    }

    &-button {
      margin-top 20px
    }
  }
}


.medium-selection {

  &__tile {
    position relative
    display block
    text-align center
    aspect-ratio 4 / 3
    padding 8px
    background $color-light-gray
    border-radius $border-radius-default
    box-shadow none
    transition box-shadow $duration-transition-hover-default ease-in-out, color $duration-transition-hover-default ease-in-out

    &:has(input:checked),
    &:hover {
      box-shadow inset 0 0 0 2px $color-brand-green
    }

    img {
      display block
      width 100%
      height 100%
      object-fit contain
    }

    input {
      position absolute
      opacity 0
    }
  }

  &__edit-button {
    position absolute
    width 40px
    aspect-ratio 1
    font-size 24px
    display flex
    justify-content center
    align-items center
    background $color-background-default
    border 1px solid $color-light-gray
    bottom 8px
    left 8px
    transition color $duration-transition-hover-default ease-in-out

    &:hover {
      color $color-brand-green
    }
  }

  &__button-wrapper {
    margin-top 32px
    display flex
    flex-direction column
    gap 24px
  }

}
</style>
