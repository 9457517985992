<template>
  <div class="link-field-group grid-row">
    <div class="col-6">
      <BaseTextInput
        v-model="v$.link.url.$model"
        :placeHolder="componentLabels.url?.placeHolder"
        :validator="v$.link.url"
        :update-emit-strategy="urlFieldUpdateEmitStrategy"
        class="form-field--small"
        :readonly="!editable"
        @blur="urlFieldUpdateEmitStrategy = 'eager'"
      />
    </div>
    <div class="col-6 d--flex">
      <BaseTextInput
        v-model="v$.link.linkText.$model"
        :placeHolder="componentLabels.linkText?.placeHolder"
        :validator="v$.link.linkText"
        :readonly="!editable"
        class="form-field--small"
      />
      <button
        v-if="editable"
        class="link-field-group__remove-button"
        @click="onRemoveLinkClick(linkIndex)"
      >
        <SvgIcon icon="trash-alt" />
      </button>
    </div>
  </div>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import { url, required } from "@vuelidate/validators";
import BaseTextInput from "@/components/form/BaseTextInput";
import SvgIcon from "@/components/common/SvgIcon";

export default {
  name: "ArticleLinkFieldGroup",
  components: { SvgIcon, BaseTextInput },
  props: {
    linkIndex: {
      type: Number,
      default: 0,
    },
    modelValue: {
      type: Object,
      default() {
        return {
          url: null,
          linkText: null,
        };
      },
    },
    componentLabels: {
      type: Object,
      default() {
        return {};
      },
    },
    editable: {
      type: Boolean,
      default: true,
    },
  },
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  data() {
    return {
      link: this.modelValue,
      urlFieldUpdateEmitStrategy: "lazy",
    };
  },
  watch: {
    link: {
      handler(value) {
        this.$emit("update:modelValue", value);
      },
      deep: true,
    },
    modelValue: {
      handler(value) {
        this.link = value;
      },
      deep: true,
    },
  },
  methods: {
    onRemoveLinkClick(indexToRemove) {
      this.$emit("removeLinkAtIndex", indexToRemove);
    },
  },
  emits: ["update:modelValue", "removeLinkAtIndex"],
  validations() {
    return {
      link: {
        url: {
          required,
          url,
        },
        linkText: {
          required,
        },
      },
    };
  },
};
</script>

<style lang="stylus" scoped>
.link-field-group {
  &__remove-button {
    flex 0 0 auto
    cursor pointer
    padding 8px
    margin-left 16px
    color inherit
    transition color $duration-transition-hover-default ease-in-out

    &:hover {
      color $color-brand-green
    }
  }
}
</style>
