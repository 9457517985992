<template>
  <section class="article-slot">
    <ArticleSlotHeader
      :slot-type="slotType"
      :slot-short-key="slotShortKey"
      :component-labels="componentLabels"
      :slot-length="article.slots?.length ?? 0"
      :slot-index="slotIndex"
      :editable="editable"
      @delete-slot="onDeleteClick"
      @shift-slot="onShiftSlotClick"
    />
    <main class="article-slot__main">
      <pre
        v-for="(dataValue, dataKey) in slotData"
        :key="`data-entry-${dataKey}`"
        >{{ dataKey }}: {{ dataValue }}</pre
      >
    </main>
  </section>
</template>
<script>
import articleSlotMixin from "@/mixins/articleSlotMixin";
import ArticleSlotHeader from "@/components/article/ArticleSlotHeader.vue";

export default {
  name: "ArticleDummySlot",
  components: { ArticleSlotHeader },
  mixins: [articleSlotMixin],
  props: {
    slotIndex: {
      type: Number,
      default: 0,
    },
    slotType: {
      type: String,
      default: null,
    },
    slotData: {
      type: Object,
      default() {
        return {};
      },
    },
  },
};
</script>

<style scoped lang="stylus">
.article-slot {
  background-color $color-background-table
  box-shadow $box-shadow-interactive-element
  border-radius $border-radius-default

  &__header {
    height 60px
    display flex
    align-items center
    gap 8px
    padding-left 32px
    border-bottom 1px solid $color-border-default
    font-size $font-size.l
  }

  &__main {
    padding 32px
  }
}
</style>
