<template>
  <div class="reset-password container d--flex d--flex-center">
    <div class="reset-password__form-wrapper">
      <template v-if="!successMessage">
        <h1 class="headline headline--main">
          {{ labels?.password_reset?.setNewPassword?.headline }}
        </h1>
        <p class="mt--3">
          {{ labels?.password_reset?.setNewPassword?.description }}
        </p>
        <form action="/" novalidate @submit.prevent="onFormSubmit">
          <BaseTextInput
            v-model="password"
            :validator="v$.password"
            type="password"
            :label="labels?.password_reset?.setNewPassword?.password"
            name="password"
            class="mt--4"
          />
          <BaseTextInput
            v-model="passwordRepeat"
            :validator="v$.passwordRepeat"
            type="password"
            :label="labels?.password_reset?.setNewPassword?.passwordRepeat"
            name="passwordRepeat"
            class="mt--3"
            @blur="v$.passwordRepeat.$touch"
          />
          <div v-if="errorMessage" class="mt--3">
            <p class="form-field__error-message">{{ errorMessage }}</p>
          </div>
          <BaseButton
            type="submit"
            :text="labels?.password_reset?.setNewPassword?.button"
            class="button mt--3"
            :class="errorMessage ? 'mt--3' : 'mt--5'"
          />
        </form>
      </template>
      <p class="t--center" v-else>{{ successMessage }}</p>
    </div>
  </div>
</template>
<script>
import BaseTextInput from "@/components/form/BaseTextInput.vue";
import useVuelidate from "@vuelidate/core";
import { mapActions, mapState } from "vuex";
import BaseButton from "@/components/elements/BaseButton.vue";
import { required, sameAs } from "@vuelidate/validators";

export default {
  name: "resetPassword",
  components: { BaseButton, BaseTextInput },
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  computed: {
    ...mapState("common", ["labels"]),
  },
  data() {
    return {
      email: null,
      password: null,
      passwordRepeat: null,
      mailSend: false,
      errorMessage: null,
      successMessage: null,
      token: this.$route.params.token,
    };
  },
  methods: {
    ...mapActions("user", ["setNewPassword"]),
    async onFormSubmit() {
      this.errorMessage = null;
      const isValid =
        (await this.v$.$validate()) && (await this.v$.$validate());
      if (!isValid) return;
      const result = await this.setNewPassword({
        password: this.password,
        token: this.token,
      });

      this.successMessage = result?.success ? result.message : null;
      this.errorMessage = result?.errorMessage;
    },
  },
  validations() {
    return {
      password: {
        required,
      },
      passwordRepeat: {
        required,
        passwordRepeat: sameAs(this.password),
      },
    };
  },
};
</script>

<style scoped lang="stylus">
.reset-password {
  flex 1

  &__form-wrapper {
    width (100% / 2)
  }
}
</style>
