<template>
  <div class="text-editor">
    <template v-if="!readonly && editor">
      <div class="text-editor__menu-bar">
        <button
          v-if="enabledMarks.includes('bold')"
          type="button"
          class="text-editor__button"
          :class="{
            'text-editor__button--active': editor.isActive('bold'),
          }"
          @click="editor.chain().focus().toggleBold().run()"
        >
          <SvgIcon icon="bold" />
        </button>
        <button
          v-if="enabledMarks.includes('italic')"
          type="button"
          class="text-editor__button"
          :class="{
            'text-editor__button--active': editor.isActive('italic'),
          }"
          @click="editor.chain().focus().toggleItalic().run()"
        >
          <SvgIcon icon="italic" />
        </button>
        <button
          v-if="enabledMarks.includes('underline')"
          type="button"
          class="text-editor__button"
          :class="{
            'text-editor__button--active': editor.isActive('underline'),
          }"
          @click="editor.chain().focus().toggleUnderline().run()"
        >
          <SvgIcon icon="underline" />
        </button>
        <button
          v-if="enabledMarks.includes('bulletList')"
          type="button"
          class="text-editor__button"
          :class="{
            'text-editor__button--active': editor.isActive('bulletList'),
          }"
          @click="editor.chain().focus().toggleBulletList().run()"
        >
          <SvgIcon icon="list-ul" />
        </button>
        <button
          v-if="enabledMarks.includes('orderedList')"
          type="button"
          class="text-editor__button"
          :class="{
            'text-editor__button--active': editor.isActive('orderedList'),
          }"
          @click="editor.chain().focus().toggleOrderedList().run()"
        >
          <SvgIcon icon="list-ol" />
        </button>
        <button
          v-if="enabledMarks.includes('heading')"
          type="button"
          class="text-editor__button"
          :class="{
            'text-editor__button--active': editor.isActive('heading', {
              level: 5,
            }),
          }"
          @click="editor.chain().focus().toggleHeading({ level: 5 }).run()"
        >
          <SvgIcon icon="heading" />
        </button>
        <button
          v-if="enabledMarks.includes('link')"
          type="button"
          class="text-editor__button"
          :class="{
            'text-editor__button--active': editor.isActive('link'),
          }"
          @click="onLinkClick"
        >
          <SvgIcon icon="link" />
        </button>
      </div>
      <div class="text-editor__text-area" @click="editor.chain().focus()">
        <EditorContent :editor="editor" />
      </div>
      <div
        v-if="linkMenuIsActive"
        class="text-editor__link-input-wrapper"
      ></div>
    </template>
    <div v-else v-html="modelValue" class="text-editor__readonly-content"></div>
  </div>
</template>

<script>
import { Editor, EditorContent } from "@tiptap/vue-3";
import Link from "@tiptap/extension-link";
import { Underline } from "@tiptap/extension-underline";
import StarterKit from "@tiptap/starter-kit";
import SvgIcon from "@/components/common/SvgIcon";

export default {
  name: "BaseTextEditor",
  components: {
    SvgIcon,
    EditorContent,
  },
  props: {
    modelValue: {
      type: String,
      default: "",
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    enabledMarks: {
      type: Array,
      default() {
        return [
          "bold",
          "italic",
          "underline",
          "bulletList",
          "orderedList",
          "link",
        ];
      },
    },
  },
  data() {
    return {
      editor: null,
      linkMenuIsActive: false,
    };
  },
  watch: {
    modelValue(value) {
      if (this.editor.getHTML() === value) return;
      this.editor.commands.setContent(value, false);
    },
  },
  mounted() {
    this.editor = new Editor({
      extensions: [
        StarterKit.configure({}),
        Link.configure({
          openOnClick: false,
          HTMLAttributes: {
            rel: "noopener noreferrer",
          },
        }),
        Underline,
      ],
      content: this.modelValue,
      onUpdate: () => {
        this.$emit("update:modelValue", this.editor.getHTML());
      },
    });
  },
  beforeUnmount() {
    this.editor.destroy();
  },
  methods: {
    onLinkClick() {
      const previousUrl = this.editor.getAttributes("link").href;
      this.linkMenuIsActive = true;
      const url = window.prompt("URL", previousUrl);
      // cancelled
      if (url === null) {
        return;
      }
      // empty
      if (url === "") {
        this.editor.chain().focus().extendMarkRange("link").unsetLink().run();
        return;
      }
      // update link
      this.editor
        .chain()
        .focus()
        .extendMarkRange("link")
        .setLink({ href: url })
        .run();
    },
  },
};
</script>

<style lang="stylus" scoped>
.text-editor {

  &__menu-bar {
    display flex
    height 40px
    border-radius 2px 2px 0 0
    border 1px solid $color-border-default
    overflow hidden
  }

  &__button {
    flex 1
    max-width 300px
    display flex
    justify-content center
    align-items center
    cursor pointer
    background-color $color-lighter-gray
    transition color $duration-transition-hover-default ease-in-out

    &:hover,
    &--active {
      color $color-brand-green
    }

    &--disabled {
      opacity 0.5
      pointer-events none
      cursor default
    }

    & + & {
      margin-left 1px
    }
  }


  &__text-area {
    padding 8px 16px
    border 1px solid $color-border-default
    border-top-color transparent
    min-height 190px
    border-radius 0 0 2px 2px

    &--readonly {
      border-top-color $color-border-default
    }

    ^[0]__readonly-content:deep(),
    &:deep(*) {

      h3, h4, h5 {
        font inherit
        font-weight $font-weight-bold
      }

      ul, ol {
        margin 1em 0
        padding-left 40px
      }

      ol li {
        list-style-type decimal
      }

      ul li {
        list-style-type disc
      }

      a {
        text-decoration underline
        color $color-brand-green
      }

      em {
        font-style italic
      }
    }
  }

}
</style>
