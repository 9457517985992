<template>
  <dialog
    ref="modal"
    class="modal"
    @close="showModal = false"
    @click.self="onBackdropClick"
  >
    <div class="modal__outer-wrapper">
      <button
        type="button"
        class="modal__close-button"
        @click="showModal = false"
      >
        <SvgIcon icon="times" class="modal__close-button-icon" />
      </button>
      <div class="modal__content-wrapper">
        <slot name="default"></slot>
      </div>
    </div>
  </dialog>
</template>
<script>
import SvgIcon from "@/components/common/SvgIcon.vue";

export default {
  name: "BaseDialog",
  components: {
    SvgIcon,
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    closeModalOnBackdropClick: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      showModal: this.open,
    };
  },
  watch: {
    open: {
      handler(newValue) {
        this.showModal = newValue;
      },
      immediate: true,
    },
    showModal: {
      handler(newValue) {
        if (newValue) {
          this.$nextTick(() => {
            this.$refs.modal?.showModal();
          });
        } else {
          this.$refs.modal.close();
        }
        this.$emit("modal-show-change", newValue);
      },
    },
  },
  methods: {
    onBackdropClick() {
      if (this.closeModalOnBackdropClick) {
        this.showModal = false;
      }
    },
  },
  emits: ["modal-show-change"],
};
</script>
<style lang="stylus" scoped>

@keyframes open {
  from {
    opacity 0
  }
  to {
    opacity 1
  }
}

@keyframes close {
  from {
    opacity 1
  }
  to {
    opacity 0
  }
}

.modal {

  --transition-duration 0.3s

  margin auto
  width calc(100% - 32px)
  max-width 920px
  max-height calc(100% - 32px)
  border none
  overflow hidden
  padding 8px 32px 32px
  border-radius $border-radius-default
  background-color $color-background-default
  box-shadow $box-shadow-interactive-element

  &__outer-wrapper {
    display flex
    flex-direction column
    overflow hidden
  }

  transition display var(--transition-duration) allow-discrete, overlay var(--transition-duration) allow-discrete
  animation close var(--transition-duration) forwards

  &::backdrop {
    background-color rgba($color-anthracite, 0.5)
    animation close var(--transition-duration) forwards
  }

  &[open] {
    display flex
    flex-direction column
    animation open var(--transition-duration) forwards

    &::backdrop {
      animation open var(--transition-duration) forwards
    }
  }

  &__close-button {
    position sticky
    margin 0 0 0 auto
    z-index 5
    top 0
    display flex
    justify-content center
    align-items center
    background-color $color-background-default
    width 40px
    aspect-ratio 1 / 1
    font-size 24px
    cursor pointer

    &-icon {
      display block
    }
  }

  &__content-wrapper {
    overflow auto
    background-color $color-background-default
  }
}
</style>
