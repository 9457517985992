import axiosInstance from "@/utils/axiosInstance";

const state = {
  imprints: {
    new: {},
  },
  dirtyImprintList: [],
  imprintList: {},
};

const getters = {
  getImprintById: (state) => (id) => {
    return state.imprints?.[id];
  },
  getImprintListByTeamId: (state) => (teamId) => {
    return state.imprintList?.[teamId];
  },
  getImprintIsDirtyById: (state) => (id) => {
    return state.dirtyImprintList.find((e) => e === id);
  },
};

const actions = {
  resetDirtyImprintList({ commit }) {
    commit("RESET_DIRTY_IMPRINT_LIST");
  },
  setImprintIsDirtyById({ commit }, { value }) {
    commit("SET_IMPRINT_IS_DIRTY", value);
  },
  unsetImprintIsDirtyById({ commit }, { value }) {
    commit("UNSET_IMPRINT_IS_DIRTY", { value: value });
  },
  async fetchImprint({ commit, dispatch }, imprintId) {
    dispatch("ui/toggleLockLayer", true, { root: true });
    if (!imprintId) return;
    try {
      const result = await axiosInstance.get(`imprints/${imprintId}`);
      commit("SET_IMPRINT", result.data[0]);
      dispatch("ui/toggleLockLayer", false, { root: true });
    } catch (e) {
      console.warn("fetchImprint failed", e);
      dispatch("ui/toggleLockLayer", false, { root: true });
    }
  },
  async putImprint({ state, commit, dispatch }, imprintId) {
    dispatch("ui/toggleLockLayer", true, { root: true });
    try {
      const result = await axiosInstance.put(
        `imprints/${imprintId}`,
        state.imprints[imprintId]
      );
      commit("SET_IMPRINT", result.data);
      dispatch("ui/toggleLockLayer", false, { root: true });
    } catch (e) {
      console.warn("putImprint failed", e);
      dispatch("ui/toggleLockLayer", false, { root: true });
    }
  },
  async postImprint({ state, commit, dispatch }, { advertorialId, teamId }) {
    dispatch("ui/toggleLockLayer", true, { root: true });
    try {
      const result = await axiosInstance.post(`imprints`, {
        ...state.imprints["new"],
        advertorialId,
      });
      commit("SET_IMPRINT", result.data);
      commit("RESET_IMPRINT", "new");
      await dispatch("fetchImprintList", teamId);
      await dispatch(
        "advertorial/updateAdvertorial",
        {
          advertorialId,
          prop: "imprintId",
          value: result.data.id,
        },
        { root: true }
      );
      dispatch("ui/toggleLockLayer", false, { root: true });
    } catch (e) {
      console.warn("putImprint failed", e);
      dispatch("ui/toggleLockLayer", false, { root: true });
    }
  },
  async fetchImprintList({ commit }, teamId) {
    if (!teamId) {
      console.warn("teamId missing. cannot fetchImprintList");
      return Promise.reject();
    }
    try {
      const result = await axiosInstance.get(`imprints`, {
        params: {
          teamId,
        },
      });
      commit("SET_IMPRINT_LIST", { list: result.data, teamId });
    } catch (e) {
      console.warn("fetchImprint failed", e);
    }
  },
  updateImprint({ commit }, { imprintId, prop, value }) {
    commit("UPDATE_IMPRINT_PROP", {
      id: imprintId,
      prop,
      value,
    });
    if (!state.dirtyImprintList.find((e) => e === imprintId)) {
      commit("SET_IMPRINT_IS_DIRTY", imprintId);
    }
  },
};

const mutations = {
  SET_IMPRINT: (state, payload) => {
    state.imprints[payload.id] = payload;
  },
  SET_IMPRINT_LIST: (state, payload) => {
    state.imprintList[payload.teamId] = payload.list;
  },
  UPDATE_IMPRINT_PROP: (state, payload) => {
    state.imprints[payload.id][payload.prop] = payload.value;
  },
  SET_IMPRINT_IS_DIRTY: (state, payload) => {
    state.dirtyImprintList.push(payload);
  },
  UNSET_IMPRINT_IS_DIRTY: (state, payload) => {
    if (state.dirtyImprintList.find((e) => e === payload)) {
      state.dirtyImprintList.splice(state.dirtyImprintList.indexOf(payload), 1);
    }
  },
  RESET_IMPRINT: (state, payload) => {
    state.imprints[payload] = {};
  },
  RESET_DIRTY_IMPRINT_LIST: (state) => {
    state.dirtyImprintList = [];
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
