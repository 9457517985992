import { createStore } from "vuex";
import VuexPersistence from "vuex-persist";
import CommonStore from "@/store/modules/common";
import MessagesStore from "@/store/modules/messages";
import AdvertorialsStore from "@/store/modules/advertorials";
import AdvertorialStore from "@/store/modules/advertorial";
import ArticleStore from "@/store/modules/article";
import ImprintStore from "@/store/modules/imprint";
import UserStore from "@/store/modules/user";
import UIStore from "@/store/modules/ui";

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  reducer: (state) => ({
    common: state["common"],
    user: state["user"],
  }),
});

export default createStore({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    common: CommonStore,
    messages: MessagesStore,
    advertorials: AdvertorialsStore,
    articles: ArticleStore,
    user: UserStore,
    advertorial: AdvertorialStore,
    imprint: ImprintStore,
    ui: UIStore,
  },
  plugins: [vuexLocal.plugin],
});
