import { mapActions, mapState } from "vuex";
import slotShortKey from "@/utils/slotShortKey";

const articleSlotMixin = {
  props: {
    editable: {
      type: Boolean,
      default: true,
    },
    advertorialId: {
      type: String,
      default: null,
      mandatory: true,
    },
    article: {
      type: Object,
      default: null,
    },
    slotIndex: {
      type: Number,
      default: 0,
    },
    slotType: {
      type: String,
      default: null,
    },
    slotData: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  computed: {
    ...mapState("common", ["labels"]),
    slotShortKey() {
      return slotShortKey(this.slotType);
    },
    componentLabels() {
      return (
        this.labels?.advertorial?.article?.slots?.[this.slotShortKey] || {}
      );
    },
  },
  methods: {
    ...mapActions("articles", [
      "updateArticle",
      "updateIsDirty",
      "deleteArticleSlot",
      "shiftArticleSlot",
    ]),
    onDeleteClick() {
      this.deleteArticleSlot({
        advertorialId: this.advertorialId,
        slotIndex: this.slotIndex,
      });
      this.updateIsDirty({
        value: true,
      });
    },
    onShiftSlotClick({ dir }) {
      this.shiftArticleSlot({
        advertorialId: this.advertorialId,
        slotIndex: this.slotIndex,
        dir,
      });
      this.updateIsDirty({
        value: true,
      });
    },
    updateStore(prop, value) {
      this.updateArticle({
        advertorialId: this.advertorialId,
        category: "slot",
        slotIndex: this.slotIndex,
        prop,
        value,
      });
      this.updateIsDirty({
        value: true,
      });
    },
  },
};

export default articleSlotMixin;
