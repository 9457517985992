<template>
  <BaseButton
    icon="share"
    text="Link teilen"
    class="button--black share-button"
    @click="openModal"
  />
  <modal ref="modal" @click="outsideClick">
    <div class="modal--container">
      <button @click="closeModal" class="button-close">
        <svg-icon icon="times" class="icon-close" />
      </button>

      <div>
        <BaseCheckbox class="checkbox" v-model="isShared"
          >Link teilen</BaseCheckbox
        >
      </div>
      <div>
        <CopyField
          :copy-string="shareUrl"
          label="URL"
          :copy-success-message="componentLabels.copySuccessMessage"
          :class="!isShared ? '--disable' : ''"
        />
      </div>
    </div>
  </modal>
</template>

<script>
import Modal from "@/components/common/Modal";
import BaseButton from "@/components/elements/BaseButton";
import SvgIcon from "@/components/common/SvgIcon";
import BaseCheckbox from "@/components/form/BaseCheckbox";
import CopyField from "@/components/advertorial/CopyField";
import { mapState } from "vuex";

export default {
  name: "ShareButton",
  components: { CopyField, BaseCheckbox, SvgIcon, Modal, BaseButton },
  props: {
    advertorialIsShared: {
      type: Boolean,
    },
    shareUrl: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      isShared: this.advertorialIsShared,
    };
  },
  computed: {
    ...mapState("common", ["labels"]),
    componentLabels() {
      return this.labels?.advertorial?.badge || {};
    },
    model: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
  },
  watch: {
    isShared(newValue) {
      if (newValue !== this.advertorialIsShared) {
        this.$emit("sharedClicked", newValue);
      }
    },
  },

  methods: {
    closeModal() {
      this.$refs.modal.close();
    },
    openModal() {
      this.$refs.modal.open();
    },
    outsideClick(event) {
      if (event.target.classList.contains("modal--backdrop")) {
        this.closeModal();
      }
    },
  },
  emits: ["update:modelValue", "sharedClicked"],
};
</script>

<style scoped>
.share-button {
  width: auto;
  margin-left: auto;
  min-height: 2.5rem;
}

.article-preview__link-live + .share-button {
  margin-left: 0;
}

.modal--container {
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 11;
  background-color: #ffffff;
  width: 45%;
  gap: 20px;
  max-height: 90vh;
  padding: 30px;
  border-radius: 6px;
}
.checkbox {
  cursor: pointer;
}
.button-close {
  position: absolute;
  right: 10px;
  top: 10px;
  width: 20px;
  height: 20px;
  margin-left: auto;
  transition: color 0.2s ease-in-out;
  cursor: pointer;
}

.--disable {
  opacity: 0.2;
  pointer-events: none;
}

.button-close:hover {
  color: #95c11c;
}
</style>
