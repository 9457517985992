<template>
  <section class="article-slot">
    <ArticleSlotHeader
      :slot-type="slotType"
      :slot-short-key="slotShortKey"
      :slot-index="slotIndex"
      :slot-length="article.slots?.length ?? 0"
      :component-labels="componentLabels"
      :editable="editable"
      @delete-slot="onDeleteClick"
      @shift-slot="onShiftSlotClick"
    />
    <main class="article-slot__main">
      <BaseTextInput
        v-model="v$.linkText.$model"
        :label="componentLabels?.linkText?.label"
        :validator="v$.linkText"
        :readonly="!editable"
        :is-required="editable"
        class="form-field--small mt--3"
      />
      <BaseTextInput
        v-model="v$.url.$model"
        :label="componentLabels?.url?.label"
        :validator="v$.url"
        :readonly="!editable"
        :is-required="editable"
        class="form-field--small mt--3"
      />
    </main>
  </section>
</template>
<script>
import BaseTextInput from "@/components/form/BaseTextInput.vue";
import { required, url, maxLength } from "@vuelidate/validators";
import ArticleSlotHeader from "@/components/article/ArticleSlotHeader.vue";
import articleSlotMixin from "@/mixins/articleSlotMixin";
import useVuelidate from "@vuelidate/core";

export default {
  name: "ArticleCtaSlot",
  components: { ArticleSlotHeader, BaseTextInput },
  mixins: [articleSlotMixin],
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  computed: {
    linkText: {
      get() {
        return this.article?.slots[this.slotIndex]?.linkText;
      },
      set(value) {
        this.updateStore("linkText", value);
      },
    },
    url: {
      get() {
        return this.article?.slots[this.slotIndex]?.url;
      },
      set(value) {
        this.updateStore("url", value);
      },
    },
  },
  validations() {
    return {
      linkText: {
        required,
        maxLength: maxLength(40),
      },
      url: {
        required,
        url,
      },
    };
  },
};
</script>
