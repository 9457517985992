import { createRouter, createWebHistory } from "vue-router";
import store from "@/store";
import Login from "@/views/Login";
import OnBoarding from "@/views/OnBoarding";
import ResetPassword from "@/views/ResetPassword";
import ResetPasswordSendToken from "@/views/ResetPasswordSendToken";
import Home from "@/views/Home.vue";
import AdvertorialArticle from "@/views/advertorial/Article.vue";
import AdvertorialBriefing from "@/views/advertorial/Briefing";
import AdvertorialImprint from "@/views/advertorial/Imprint";
import AdvertorialPreview from "@/views/advertorial/PreviewAndFeedback";
import AdvertorialBadge from "@/views/advertorial/Badge";
import PathNotFound from "@/views/PathNotFound";
import AdvertorialMedia from "@/views/advertorial/Media.vue";

const routes = [
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: { pageWrapperModifierClass: "page-wrapper--bg-green" },
  },
  {
    path: "/onboarding/:id",
    name: "OnBoarding",
    component: OnBoarding,
    beforeEnter: (to, from, next) => {
      if (store.getters["user/getLoginStatus"]) {
        next("/");
      }
      next();
    },
  },
  {
    path: "/reset-password/:token",
    name: "ResetPassword",
    component: ResetPassword,
    beforeEnter: (to, from, next) => {
      if (store.getters["user/getLoginStatus"]) {
        next("/");
      }
      next();
    },
  },
  {
    path: "/reset-password",
    name: "ResetPasswordSendToken",
    component: ResetPasswordSendToken,
    beforeEnter: (to, from, next) => {
      if (store.getters["user/getLoginStatus"]) {
        next("/");
      }
      next();
    },
  },
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: { requiresAuth: true },
  },
  {
    path: "/advertorial/:id/briefing",
    name: "AdvertorialBriefing",
    component: AdvertorialBriefing,
    meta: { requiresAuth: true },
  },
  {
    path: "/advertorial/:id/article",
    name: "AdvertorialArticle",
    component: AdvertorialArticle,
    meta: { requiresAuth: true },
  },
  {
    path: "/advertorial/:id/medien",
    name: "AdvertorialMedia",
    component: AdvertorialMedia,
    meta: { requiresAuth: true },
  },
  {
    path: "/advertorial/:id/bilder",
    redirect: { name: "AdvertorialMedia" },
    meta: { requiresAuth: true },
  },
  {
    path: "/advertorial/:id/impressum",
    name: "AdvertorialImprint",
    component: AdvertorialImprint,
    meta: { requiresAuth: true },
  },
  {
    path: "/advertorial/:id/vorschau",
    name: "AdvertorialPreview",
    component: AdvertorialPreview,
    meta: { requiresAuth: true },
  },
  {
    path: "/advertorial/:id/vorschau-und-feedback",
    redirect: { name: "AdvertorialPreview" },
    meta: { requiresAuth: true },
  },
  {
    path: "/advertorial/:id/badge",
    name: "AdvertorialBadge",
    component: AdvertorialBadge,
    meta: { requiresAuth: true },
  },
  {
    path: "/service/imprint",
    name: "Imprint",
    // route level code-splitting
    // this generates a separate chunk (imprint.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "imprint" */ "@/views/service/Imprint"),
  },
  {
    path: "/:pathMatch(.*)*",
    component: PathNotFound,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (store.getters["user/getLoginStatus"]) {
      next();
      return;
    }
    next("/login");
  } else {
    next();
  }
});

export default router;
