import axiosInstance from "@/utils/axiosInstance";
import { MEDIUM_FORM_FIELDS, MEDIUM_PURPOSE_TYPES } from "@/constants";

const state = {
  advertorials: {},
  isDirty: false,
};

const getters = {
  getAdvertorialById: (state) => (id) => {
    return state.advertorials?.[id];
  },
  getIsDirty: (state) => {
    return state.isDirty;
  },
};

const actions = {
  async fetchAdvertorial({ commit, dispatch }, advertorialId) {
    dispatch("ui/toggleLockLayer", true, { root: true });
    try {
      const result = await axiosInstance.get(`advertorials/${advertorialId}`);
      commit("SET_ADVERTORIAL", result.data[0]);
      dispatch("ui/toggleLockLayer", false, { root: true });
    } catch (e) {
      console.warn("fetchAdvertorial failed", e);
      dispatch("ui/toggleLockLayer", false, { root: true });
    }
  },
  async patchAdvertorial({ state, commit, dispatch }, advertorialId) {
    dispatch("ui/toggleLockLayer", true, { root: true });
    state.isDirty = false;
    try {
      const result = await axiosInstance.patch(
        `advertorials/${advertorialId}`,
        {
          briefing: state.advertorials[advertorialId].briefing,
          shared: state.advertorials[advertorialId].shared,
          ...(state.advertorials[advertorialId].imprintId && {
            imprintId: state.advertorials[advertorialId].imprintId,
          }),
        },
        {
          headers: {
            ...axiosInstance.headers,
            "Content-Type": "application/merge-patch+json",
          },
        }
      );
      commit("SET_ADVERTORIAL", result.data);
      dispatch("ui/toggleLockLayer", false, { root: true });
    } catch (e) {
      console.warn("patchAdvertorial failed", e);
      alert(e.response.message);
      dispatch("ui/toggleLockLayer", false, { root: true });
    }
  },
  async postAdvertorialMediums({ dispatch, state }, advertorialId) {
    dispatch("ui/toggleLockLayer", true, { root: true });
    state.isDirty = false;
    try {
      const newFiles = [
        ...state.advertorials[advertorialId].files[
          MEDIUM_PURPOSE_TYPES.frontend
        ],
        ...state.advertorials[advertorialId].files[
          MEDIUM_PURPOSE_TYPES.briefing
        ],
      ]?.filter((medium) => medium?.file instanceof File || medium?.changed);
      const result = await Promise.all(
        newFiles.map((medium) => {
          const data = Object.entries(medium).reduce(
            (formDataObject, field) => {
              if (field[1] && MEDIUM_FORM_FIELDS.includes(field[0])) {
                formDataObject.append(field[0], field[1]);
              }
              return formDataObject;
            },
            new FormData()
          );
          return axiosInstance.post(
            `advertorials/${advertorialId}/medium`,
            data,
            {
              headers: {
                ...axiosInstance.headers,
                "Content-Type": "multipart/form-data",
              },
            }
          );
        })
      );
      dispatch("ui/toggleLockLayer", false, { root: true });
      return result;
    } catch (e) {
      console.warn("postAdvertorialMediums failed ", e);
      alert(e.response?.data?.message);
      dispatch("ui/toggleLockLayer", false, { root: true });
    }
  },
  async deleteAdvertorialMedium({ dispatch }, { mediumId, advertorialId }) {
    dispatch("ui/toggleLockLayer", true, { root: true });
    try {
      const result = await axiosInstance.delete(
        `advertorials/${advertorialId}/medium/${mediumId}`
      );
      dispatch("ui/toggleLockLayer", false, { root: true });
      return result;
    } catch (e) {
      console.warn("deleteAdvertorialMedium failed ", e);
      alert(e.response?.data?.message);
      dispatch("ui/toggleLockLayer", false, { root: true });
    }
  },
  async approveBriefing({ dispatch, commit }, advertorialId) {
    dispatch("ui/toggleLockLayer", true, { root: true });
    try {
      const result = await axiosInstance.put(
        `advertorials/${advertorialId}/approve-briefing`
      );
      dispatch("ui/toggleLockLayer", false, { root: true });
      commit("SET_ADVERTORIAL", result.data);
      return result;
    } catch (e) {
      console.warn("approveAdvertorial failed ", e);
      alert(e.response?.data?.message);
      dispatch("ui/toggleLockLayer", false, { root: true });
    }
  },
  async approveAdvertorial({ dispatch, commit }, advertorialId) {
    dispatch("ui/toggleLockLayer", true, { root: true });
    try {
      const result = await axiosInstance.put(
        `advertorials/${advertorialId}/approve-advertorial`
      );
      dispatch("ui/toggleLockLayer", false, { root: true });
      commit("SET_ADVERTORIAL", result.data);
      return result;
    } catch (e) {
      console.warn("approveAdvertorial failed ", e);
      alert(e.response?.data?.message);
      dispatch("ui/toggleLockLayer", false, { root: true });
    }
  },
  async submitAdvertorial({ dispatch, commit }, advertorialId) {
    dispatch("ui/toggleLockLayer", true, { root: true });
    try {
      const result = await axiosInstance.put(
        `self-service-articles/${advertorialId}/send-draft-to-burda`
      );
      dispatch("ui/toggleLockLayer", false, { root: true });
      commit("SET_ADVERTORIAL", result.data);
      return result;
    } catch (e) {
      console.warn("submitAdvertorial failed ", e);
      alert(e.response?.data?.message);
      dispatch("ui/toggleLockLayer", false, { root: true });
    }
  },
  async finalizeFeedback({ dispatch, commit }, { advertorialId, message }) {
    dispatch("ui/toggleLockLayer", true, { root: true });
    try {
      const result = await axiosInstance.put(
        `advertorials/${advertorialId}/finalize-feedback`,
        { message }
      );
      dispatch("ui/toggleLockLayer", false, { root: true });
      commit("SET_ADVERTORIAL", result.data);
      return result;
    } catch (e) {
      console.warn("finalizeFeedback failed ", e);
      alert(e.response?.data?.message);
      dispatch("ui/toggleLockLayer", false, { root: true });
    }
  },
  updateIsDirty({ commit }, { value }) {
    commit("UPDATE_IS_DIRTY", { value: value });
  },
  updateAdvertorial({ commit }, { advertorialId, category, prop, value }) {
    commit("UPDATE_ADVERTORIAL_PROP", {
      id: advertorialId,
      category,
      prop,
      value,
    });
  },
};

const mutations = {
  UPDATE_IS_DIRTY: (state, payload) => {
    state.isDirty = payload.value;
  },

  SET_ADVERTORIAL: (state, payload) => {
    const advertorial = {
      files: {
        [MEDIUM_PURPOSE_TYPES.frontend]: [],
        [MEDIUM_PURPOSE_TYPES.briefing]: [],
      },
      ...payload,
    };
    if (payload.briefing?.links) {
      advertorial.briefing.links = payload.briefing.links.filter(
        (link) => link.url
      );
    }
    if (payload.media?.length) {
      payload.media.forEach((medium) => {
        advertorial.files[medium.purposeType].push(medium);
      });
    }
    state.advertorials[payload.id] = advertorial;
  },
  UPDATE_ADVERTORIAL_PROP: (state, payload) => {
    const storeTarget = payload.category
      ? state.advertorials[payload.id][payload.category]
      : state.advertorials[payload.id];
    storeTarget[payload.prop] = payload.value;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
