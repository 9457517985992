// export const API_URL_LOCAL = "http://advertorial.up.wip:14000/api/";
// export const API_URL_LOCAL = "https://dashboard.uportal.buero.plan-a.de/api";
export const API_URL_LOCAL = "/api/";
export const API_URL_PROD = "/api/";

export const ADVERTORIALS_FILTER_CONFIG = {
  title: {
    type: "string",
    props: ["title", "keyword"],
  },
  dashboardStatus: {
    type: "array",
    props: ["dashboardStatus"],
  },
  publisher: {
    type: "array",
    props: ["publisher"],
  },
};

export const MEDIUM_PURPOSE_TYPES = {
  frontend: "purpose_type_frontend",
  briefing: "purpose_type_briefing",
};

export const MEDIUM_FORM_FIELDS = [
  "file",
  "mediumId",
  "title",
  "filename",
  "externalUrl",
  "credit",
  "licenseExpiration",
  "notice",
  "purposeType",
];

export const FINALIZE_BRIEFING_ADVERTORIAL_STATUS = ["briefing_requested"];

export const FEEDBACK_ENABLED_ADVERTORIAL_STATUS = [
  "waiting_for_feedback",
  "reworking",
  "waiting_for_approval",
  "approved",
];
export const FINALIZE_FEEDBACK_ENABLED_ADVERTORIAL_STATUS = [
  "waiting_for_feedback",
  "waiting_for_approval",
];

export const RELEASE_ENABLED_ADVERTORIAL_STATUS = [
  "waiting_for_feedback",
  "waiting_for_approval",
];

export const SUBMIT_ENABLED_ADVERTORIAL_STATUS = ["self_service_writing"];

export const MESSAGE_TYPE_ICONS = {
  briefing_requested_by_editor: "pen-solid",
  briefing_sent_by_advertiser: "upload",
  briefing_reminder: "bell",
  start_writing_by_editor: "pen-solid",
  draft_sent_by_editor: "sticky-note",
  feedback_from_advertiser: "comment",
  feedback_from_editor: "burda-forward",
  feedback_approved_by_advertiser: "comment-slash",
  rework_sent_by_editor: "sticky-note",
  article_approved_by_advertiser: "check",
  article_launched: "rocket",
  article_expired: "stop-circle",
};

export const SLOT_TYPE_ICONS = {
  cta: "mouse-pointer",
  linkBox: "external-link-alt",
  youtubeVideo: "youtube",
  medium: "image-inverted",
  mediaGallery: "images",
  paragraph: "align-justify",
};
