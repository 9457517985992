import axiosInstance from "@/utils/axiosInstance";
import { sortNumerically } from "@/utils/sort";

const state = {
  articles: {},
  isDirty: false,
};

const getters = {
  getArticleByAdvertorialId: (state) => (id) => {
    return state.articles?.[id];
  },
  getIsDirty: (state) => {
    return state.isDirty;
  },
};

const actions = {
  async fetchArticle({ commit, dispatch }, advertorialId) {
    dispatch("ui/toggleLockLayer", true, { root: true });
    try {
      const result = await axiosInstance.get(
        `self-service-articles/${advertorialId}`
      );
      commit("SET_ARTICLE", { article: result.data[0], advertorialId });
      dispatch("ui/toggleLockLayer", false, { root: true });
    } catch (e) {
      console.warn("fetchArticle failed", e);
      dispatch("ui/toggleLockLayer", false, { root: true });
    }
  },
  async patchArticle({ state, commit, dispatch }, advertorialId) {
    dispatch("ui/toggleLockLayer", true, { root: true });
    try {
      const article = { ...state.articles[advertorialId] };
      if (article.slots?.length) {
        article.slots = article.slots?.map(
          // eslint-disable-next-line no-unused-vars
          ({ tempId, ...slotWithoutTempId }) => slotWithoutTempId
        );
      }
      const result = await axiosInstance.patch(
        `self-service-articles/${advertorialId}`,
        article,
        {
          headers: {
            ...axiosInstance.headers,
            "Content-Type": "application/merge-patch+json",
          },
        }
      );
      commit("SET_ARTICLE", { article: result.data, advertorialId });
      commit("UPDATE_IS_DIRTY", { value: false });
      dispatch("ui/toggleLockLayer", false, { root: true });
    } catch (e) {
      console.warn("patchAdvertorial failed", e);
      alert(e.response.message);
      dispatch("ui/toggleLockLayer", false, { root: true });
    }
  },
  updateArticle(
    { commit },
    { advertorialId, category, slotIndex, prop, value }
  ) {
    commit("UPDATE_ARTICLE_PROP", {
      id: advertorialId,
      category,
      prop,
      slotIndex,
      value,
    });
  },
  addArticleSlot({ commit }, { advertorialId, slot }) {
    commit("ADD_ARTICLE_SLOT", { id: advertorialId, slot });
  },
  deleteArticleSlot({ commit }, { advertorialId, slotIndex }) {
    commit("DELETE_ARTICLE_SLOT", { id: advertorialId, slotIndex });
  },
  shiftArticleSlot({ commit }, { advertorialId, slotIndex, dir }) {
    commit("SHIFT_ARTICLE_SLOT", { id: advertorialId, slotIndex, dir });
  },
  updateIsDirty({ commit }, { value }) {
    commit("UPDATE_IS_DIRTY", { value: value });
  },
};

const mutations = {
  UPDATE_IS_DIRTY: (state, payload) => {
    state.isDirty = payload.value;
  },
  UPDATE_ARTICLE_PROP: (state, payload) => {
    const storeTarget =
      payload.category === "stage"
        ? state.articles[payload.id].stage
        : state.articles[payload.id].slots[payload.slotIndex];
    storeTarget[payload.prop] = payload.value;
  },
  ADD_ARTICLE_SLOT: (state, payload) => {
    state.articles[payload.id].slots = [
      ...(state.articles[payload.id].slots || []),
      payload.slot,
    ].map((slot, slotIndex) => ({
      ...slot,
      sortOrder: slotIndex,
    }));
  },
  DELETE_ARTICLE_SLOT: (state, payload) => {
    const slots = [...state.articles[payload.id].slots];
    state.articles[payload.id].slots = [
      ...slots.slice(0, payload.slotIndex),
      ...slots.slice(payload.slotIndex + 1),
    ];
  },
  SHIFT_ARTICLE_SLOT: (state, payload) => {
    const slots = [...state.articles[payload.id].slots];
    const oldSlotIndex = payload.slotIndex;
    const newSlotIndex =
      payload.dir === "up" ? payload.slotIndex - 1 : payload.slotIndex + 1;
    [slots[oldSlotIndex], slots[newSlotIndex]] = [
      slots[newSlotIndex],
      slots[oldSlotIndex],
    ];
    state.articles[payload.id].slots = slots.map((slot, slotIndex) => ({
      ...slot,
      sortOrder: slotIndex,
    }));
  },
  SET_ARTICLE: (state, { article, advertorialId }) => {
    state.articles[advertorialId] = {
      ...article,
      ...(article.slots && {
        slots: sortNumerically([...article.slots], "sortOrder"),
      }),
    };
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
