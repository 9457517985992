<template>
  <div v-if="advertorial" class="container">
    <div class="grid-row">
      <div class="col-8">
        <h1 class="headline headline--main mt--8">{{ advertorial.title }}</h1>
        <TabNav />
        <MediaFigure
          v-for="(media, mediaIndex) in files"
          :key="`media-figure-${media.mediumId || media.tempId}`"
          :existingMedium="media"
          :type="media.type"
          @update:modelValue="
            (val) => updateFileAtIndex(mediaIndex, media, val)
          "
          @removeFile="onRemoveFileAtIndex(mediaIndex)"
          class="mt--5"
        />
        <template v-if="canAddMoreMedia">
          <h4 class="headline headline--section mt--5">Modul hinzufügen</h4>
          <div class="d--flex d--gap">
            <BaseButton
              icon="image-inverted"
              text="Bild hinzufügen"
              class="button--black button--angular mt--1"
              @click="onAddImageClick"
            /><BaseButton
              icon="video-solid"
              text="Video hinzufügen"
              class="button--black button--angular mt--1"
              @click="onAddVideoClick"
            />
          </div>
        </template>
        <div class="d--flex mt--10">
          <BaseButton
            :text="labels.common.save"
            icon="save"
            class="button--inline button--black"
            @click="onSaveClick"
          />
          <BaseButton
            :text="componentLabels.continueLink?.title"
            icon="arrow-right"
            iconPosition="right"
            class="button--inline ml--auto"
            @click="onContinueClick"
          />
        </div>
        <Feedback
          :advertorialId="advertorialId"
          :advertorialStatus="advertorial?.advertorialStatus"
          :showFinalizeFeedbackButton="showFinalizeFeedbackButton"
        />
      </div>
      <div class="col-4 mt--4">
        <StatusBox />
      </div>
    </div>
    <UnsavedChangesModal ref="confirmModal" />
  </div>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import advertorialMixin from "@/mixins/advertorialMixin";
import TabNav from "@/components/advertorial/TabNav";
import StatusBox from "@/components/advertorial/StatusBox";
import MediaFigure from "@/components/advertorial/MediaFigure";
import BaseButton from "@/components/elements/BaseButton";
import { mapState } from "vuex";
import { MEDIUM_PURPOSE_TYPES } from "@/constants";
import UnsavedChangesModal from "@/components/common/UnsavedChangesModal";
import Feedback from "@/components/advertorial/Feedback.vue";

export default {
  name: "Media",
  components: {
    Feedback,
    UnsavedChangesModal,
    BaseButton,
    MediaFigure,
    TabNav,
    StatusBox,
  },
  mixins: [advertorialMixin],
  props: {
    maxMediaCount: {
      type: Number,
      default: 5,
    },
    purposeType: {
      type: String,
      default: MEDIUM_PURPOSE_TYPES.frontend,
    },
  },
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  computed: {
    canAddMoreMedia() {
      return this.files?.length < this.maxMediaCount;
    },
    ...mapState("common", ["labels", "config"]),
    componentLabels() {
      return this.labels?.advertorial?.media || {};
    },
  },
  created() {
    const bindUnloadEvent = () => {
      window.addEventListener("beforeunload", this.checkIfDirty);
      window.removeEventListener("click", bindUnloadEvent);
    };
    window.addEventListener("click", bindUnloadEvent);
  },
  mounted() {
    console.log(this.$el);
  },
  methods: {
    onAddImageClick() {
      this.updateFileAtIndex(this.files.length, this.emptyFileObject("image"));
    },
    onAddVideoClick() {
      this.updateFileAtIndex(this.files.length, this.emptyFileObject("video"));
    },
    onContinueClick() {
      this.onSaveClick().then(() => {
        this.$router.push(`/advertorial/${this.advertorialId}/impressum`);
      });
    },
  },
  async beforeRouteLeave(to, from, next) {
    const leaveSite = () => {
      this.updateIsDirty({ value: false });
      window.removeEventListener("beforeunload", this.checkIfDirty);
      next();
    };

    if (this.isDirty) {
      const modal = await this.$refs.confirmModal.show({
        titleText: "Ungespeicherte Änderungen",
        mainText: ` Sie haben Änderungen vorgenommen,die bisher nicht gespeichert wurden. Sollen diese gespeichert werden?`,
        cancelText: "Änderungen verwerfen",
        confirmText: "Speichern",
      });
      if (modal !== "interrupt") {
        if (modal) {
          await this.onSaveClick();
          leaveSite();
        } else {
          await this.fetchAdvertorial(this.advertorialId);
          leaveSite();
        }
      } else {
        next(false);
      }
    } else {
      leaveSite();
    }
  },
};
</script>
