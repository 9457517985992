<template>
  <section class="feedback mt--5">
    <h2 class="headline headline--section">{{ componentLabels.title }}</h2>
    <BaseTextarea
      v-model="message"
      :placeHolder="componentLabels.placeholder"
      class="form-field--small mt--3"
      @focus="$emit('textareaFocussed')"
    />
    <div class="d--flex mt--3">
      <BaseButton
        v-if="!showFinalizeFeedbackButton"
        :text="componentLabels?.buttonSendMessage"
        icon="telegram-plane"
        class="button--inline button--black button--medium"
        :class="{ 'button--disabled': !message.length }"
        @click="onSaveClick"
      />
      <BaseButton
        v-if="showFinalizeFeedbackButton"
        :text="buttonTextLabel"
        icon="telegram-plane"
        class="button--inline button--medium"
        :class="{ 'button--disabled': !message.length }"
        @click="onFinalizeFeedbackClick"
      />
    </div>
  </section>
</template>

<script>
import BaseTextarea from "@/components/form/BaseTextarea";
import BaseButton from "@/components/elements/BaseButton";
import { mapActions, mapState } from "vuex";

export default {
  name: "Feedback",
  props: {
    advertorialId: {
      type: String,
      mandatory: true,
    },
    advertorialStatus: {
      type: String,
      default: null,
    },
    showFinalizeFeedbackButton: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      message: "",
    };
  },
  components: { BaseButton, BaseTextarea },
  emits: ["textareaFocussed"],
  computed: {
    ...mapState("common", ["labels"]),
    componentLabels() {
      return this.labels?.advertorial?.feedback || {};
    },
    buttonTextLabel() {
      return this.advertorialStatus === "waiting_for_approval"
        ? this.componentLabels?.buttonTextRework
        : this.componentLabels?.buttonTextFinalize;
    },
  },
  methods: {
    ...mapActions("messages", ["postMessage", "fetchAdvertorialMessages"]),
    ...mapActions("advertorial", ["finalizeFeedback"]),
    async onSaveClick() {
      await this.postMessage({
        advertorialId: this.advertorialId,
        message: this.message,
      });

      this.message = "";
    },
    async onFinalizeFeedbackClick() {
      await this.finalizeFeedback({
        advertorialId: this.advertorialId,
        message: this.message,
      });
      await this.fetchAdvertorialMessages({
        advertorialId: this.advertorialId,
      });
      this.message = "";
    },
  },
};
</script>
