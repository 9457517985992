const slotShortKey = (slotType) => {
  const lastPart = slotType?.split(".").pop(); // Extracts the last part
  return lastPart
    ?.split("_") // Split by underscores
    .map((word, index) =>
      index === 0 ? word : word.charAt(0).toUpperCase() + word.slice(1)
    ) // Capitalize words after the first
    .join(""); // Join back into a single string
};

export default slotShortKey;
