const sortAlphabetically = (entries, sortProp, direction = "desc") => {
  const sortedEntries = entries.sort((a, b) => {
    const compareA = sortProp ? a[sortProp] : a;
    const compareB = sortProp ? b[sortProp] : b;
    if (!compareA) return -1;
    if (!compareB) return +1;
    return compareA.localeCompare(compareB);
  });
  return direction === "asc" ? sortedEntries : sortedEntries.reverse();
};

const sortNumerically = (entries, sortProp, direction = "asc") => {
  const sortedEntries = entries.sort((a, b) => {
    const compareA = sortProp ? a[sortProp] : a;
    const compareB = sortProp ? b[sortProp] : b;
    return (compareA || 0) - (compareB || 0); // Handles undefined or null by treating them as 0
  });
  return direction === "asc" ? sortedEntries : sortedEntries.reverse();
};

export { sortAlphabetically, sortNumerically };
