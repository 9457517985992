<template>
  <div v-if="advertorial" class="article-editor container">
    <div class="grid-row">
      <div class="col-8">
        <h1 class="headline headline--main mt--8">{{ advertorial.title }}</h1>
        <TabNav />
        <ArticleStage
          :article="article"
          :advertorial-id="advertorialId"
          :editable="editable"
          class="mt--5"
        />
        <Component
          v-for="(slot, slotIndex) in article?.slots"
          :key="`slot-${slot.id || slot.tempId}`"
          :is="getSlotComponent(slot.type)"
          :advertorial-id="advertorialId"
          :slot-index="slotIndex"
          :slot-type="slot.type"
          :slot-data="slot"
          :article="article"
          :editable="editable"
          class="mt--4"
        />
        <div v-if="editable" class="mt--10">
          <h2 class="headline headline--section">Modul hinzufügen</h2>
          <div class="article-editor__buttons-wrapper mt--2">
            <BaseButton
              v-for="slot in slotTypes"
              :key="`add-slot-${slot.type}`"
              :text="slot.label"
              :icon="slot.icon"
              class="button button--black button--angular"
              @click="onAddModuleClick(slot.type)"
            />
          </div>
        </div>
        <div class="article-editor__sticky-buttons-wrapper d--flex mt--10">
          <BaseButton
            :text="labels.common.save"
            icon="save"
            class="button--inline"
            :class="{ 'button--disabled': !editable }"
            @click="onSaveArticleClick"
          />
          <BaseButton
            :text="componentLabels?.continueLink?.title"
            icon="arrow-right"
            iconPosition="right"
            class="button--inline ml--auto"
            @click="onContinueClick"
          />
        </div>
      </div>
      <div class="col-4 mt--4">
        <StatusBox />
      </div>
    </div>
    <UnsavedChangesModal ref="confirmModal" />
  </div>
</template>
<script>
import TabNav from "@/components/advertorial/TabNav.vue";
import advertorialMixin from "@/mixins/advertorialMixin";
import StatusBox from "@/components/advertorial/StatusBox.vue";
import { mapActions, mapGetters, mapState } from "vuex";
import { MEDIUM_PURPOSE_TYPES, SLOT_TYPE_ICONS } from "@/constants";
import ArticleStage from "@/components/article/ArticleStage.vue";
import BaseButton from "@/components/elements/BaseButton.vue";
import ArticleDummySlot from "@/components/article/ArticleDummySlot.vue";
import ArticleCtaSlot from "@/components/article/ArticleCtaSlot.vue";
import useVuelidate from "@vuelidate/core";
import ArticleYoutubeVideoSlot from "@/components/article/ArticleYoutubeVideoSlot.vue";
import ArticleLinkBoxSlot from "@/components/article/ArticleLinkBoxSlot.vue";
import ArticleParagraphSlot from "@/components/article/ArticleParagraphSlot.vue";
import ArticleMediumSlot from "@/components/article/ArticleMediumSlot.vue";
import ArticleGallerySlot from "@/components/article/ArticleGallerySlot.vue";
import UnsavedChangesModal from "@/components/common/UnsavedChangesModal.vue";
import slotShortKey from "@/utils/slotShortKey";

export default {
  name: "Article",
  components: {
    UnsavedChangesModal,
    ArticleDummySlot,
    BaseButton,
    ArticleStage,
    StatusBox,
    TabNav,
  },
  mixins: [advertorialMixin],
  props: {
    purposeType: {
      type: String,
      default: MEDIUM_PURPOSE_TYPES.frontend,
    },
  },
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  computed: {
    ...mapGetters("articles", {
      getArticleByAdvertorialId: "getArticleByAdvertorialId",
      getArticleIsDirty: "getIsDirty",
    }),
    ...mapState("common", ["labels"]),
    ...mapGetters("common", ["getArticleSlots"]),
    componentLabels() {
      return this.labels?.advertorial?.article || {};
    },
    article() {
      return this.getArticleByAdvertorialId(this.advertorialId);
    },
    editable() {
      //TODO: Check if there are advertorial status where the article can't be edited
      return (
        this.advertorial?.advertorialStatus !==
        "self_service_waiting_for_approval"
      );
    },
    slotTypes() {
      return this.getArticleSlots?.map((slotType) => {
        const shortKey = slotShortKey(slotType);
        return {
          label: this.componentLabels?.slots[shortKey]?.name,
          icon: SLOT_TYPE_ICONS[shortKey],
          type: slotType,
        };
      });
    },
  },
  async beforeRouteLeave(to, from, next) {
    const leaveSite = () => {
      this.updateIsDirty({ value: false });
      window.removeEventListener("beforeunload", this.checkIfArticleIsDirty);
      next();
    };

    if (this.getArticleIsDirty) {
      const modal = await this.$refs.confirmModal.show({
        titleText: "Ungespeicherte Änderungen",
        mainText: `Sie haben Änderungen vorgenommen, die bisher nicht gespeichert wurden. Sollen diese gespeichert werden?`,
        cancelText: "Änderungen verwerfen",
        confirmText: "Speichern",
      });
      if (modal !== "interrupt") {
        if (modal) {
          await this.onSaveArticleClick();
          leaveSite();
        } else {
          await this.fetchAdvertorial(this.advertorialId);
          leaveSite();
        }
      } else {
        next(false);
      }
    } else {
      leaveSite();
    }
  },
  created() {
    this.fetchArticle(this.advertorialId);
    const bindUnloadEvent = () => {
      window.addEventListener("beforeunload", this.checkIfArticleIsDirty);
      window.removeEventListener("click", bindUnloadEvent);
    };
    window.addEventListener("click", bindUnloadEvent);
  },
  methods: {
    ...mapActions("articles", [
      "fetchArticle",
      "patchArticle",
      "addArticleSlot",
    ]),
    checkIfArticleIsDirty(event) {
      if (this.getArticleIsDirty) {
        event.preventDefault();
        event.returnValue = true;
      }
    },
    async onContinueClick() {
      const isSuccess = await this.onSaveArticleClick();
      if (isSuccess) {
        await this.$router.push(`/advertorial/${this.advertorialId}/impressum`);
      } else {
        console.warn("Cannot proceed because article save failed");
      }
    },
    async onSaveArticleClick() {
      const isValid = await this.validateForm();
      if (!isValid) {
        console.error("Form validation failed");
        return false;
      }
      try {
        await this.patchArticle(this.advertorialId);
        return true; // Indicate success explicitly
      } catch (error) {
        console.error("Failed to patch article:", error);
        return false; // Return false on failure
      }
    },
    onAddModuleClick(slotType) {
      this.addArticleSlot({
        advertorialId: this.advertorialId,
        slot: { type: slotType, tempId: `tmp-${+new Date()}` },
      });
    },
    getSlotComponent(slotType) {
      const slotComponents = {
        cta: ArticleCtaSlot,
        medium: ArticleMediumSlot,
        mediaGallery: ArticleGallerySlot,
        linkBox: ArticleLinkBoxSlot,
        youtubeVideo: ArticleYoutubeVideoSlot,
        paragraph: ArticleParagraphSlot,
      };
      return slotComponents[slotShortKey(slotType)] || ArticleDummySlot;
    },
  },
};
</script>

<style lang="stylus">

.article-editor {

  &__buttons-wrapper {
    display grid
    grid-template-columns 1fr 1fr
    gap 24px
  }

  &__sticky-buttons-wrapper {
    position  sticky
    bottom 0
    padding 4px 0 8px
    background-color $color-background-default
  }
}

.article-slot {
  background-color $color-background-table
  box-shadow $box-shadow-interactive-element
  border-radius $border-radius-default

  &__header {
    height 60px
    display flex
    align-items center
    border-bottom 1px solid $color-border-default
    font-size $font-size.l
    gap 8px
    padding-left 32px


    &-button-wrapper {
      margin-left auto
      display flex
      align-self stretch
      height 100%
    }

    &-button {
      cursor pointer
      width 60px
      flex 0 0 auto
      align-self stretch
      display flex
      align-items center
      justify-content center
      border-left 1px solid $color-border-default
      font-size 20px
      color inherit
      transition color $duration-transition-hover-default ease-in-out

      &:hover {
        color $color-brand-green
      }
    }
  }

  &__main {
    padding 32px
  }
}
</style>
