<template>
  <section class="article-slot">
    <header class="article-slot__header">
      <div>{{ componentLabels.headline }}</div>
      <Tooltip>{{ componentLabels.tooltip }}</Tooltip>
    </header>
    <main class="article-slot__main">
      <BaseTextInput
        v-model="kicker"
        :label="componentLabels?.kicker?.label"
        :readonly="true"
        :is-required="false"
        class="form-field--small mt--3"
      />
      <BaseTextInput
        v-model="v$.heading.$model"
        :label="componentLabels?.heading?.label"
        :validator="v$.heading"
        :readonly="!editable"
        :is-required="editable"
        class="form-field--small mt--3"
      />
      <ArticleImage
        :medium="v$.medium.$model"
        :validator="v$.medium"
        :editable="editable"
        :is-required="editable"
        class="mt--4"
        @medium-selected="(val) => updateStore('medium', val)"
      />
      <div class="mt--6">
        <p>
          {{ componentLabels?.introduction?.label }}
        </p>
      </div>
      <BaseTextEditor
        v-model="introduction"
        :enabled-marks="['underline', 'italic', 'link']"
        :readonly="!editable"
        class="mt--1"
      />
    </main>
  </section>
</template>
<script>
import Tooltip from "@/components/common/Tooltip.vue";
import BaseTextInput from "@/components/form/BaseTextInput.vue";
import useVuelidate from "@vuelidate/core";
import { mapActions, mapState } from "vuex";
import { required } from "@vuelidate/validators";
import BaseTextEditor from "@/components/form/BaseTextEditor.vue";
import ArticleImage from "@/components/article/ArticleImage.vue";

export default {
  name: "ArticleStage",
  components: { ArticleImage, BaseTextEditor, BaseTextInput, Tooltip },
  props: {
    editable: {
      type: Boolean,
      default: true,
    },
    article: {
      type: Object,
      default: null,
    },
    advertorialId: {
      type: String,
      default: null,
      mandatory: true,
    },
  },
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  computed: {
    ...mapState("common", ["labels"]),
    componentLabels() {
      return this.labels?.advertorial?.article?.stage || {};
    },
    kicker: {
      get() {
        return this.article?.stage?.kicker;
      },
      set(value) {
        this.updateStore("kicker", value);
      },
    },
    heading: {
      get() {
        return this.article?.stage?.heading;
      },
      set(value) {
        this.updateStore("heading", value);
      },
    },
    introduction: {
      get() {
        return this.article?.stage?.introduction;
      },
      set(value) {
        this.updateStore("introduction", value);
      },
    },
    medium() {
      return this.article?.stage?.medium;
    },
  },
  methods: {
    ...mapActions("articles", ["updateArticle", "updateIsDirty"]),
    updateStore(prop, value) {
      this.updateArticle({
        advertorialId: this.advertorialId,
        category: "stage",
        prop,
        value,
      });
      this.updateIsDirty({
        value: true,
      });
    },
  },
  validations() {
    return {
      heading: {
        required,
      },
      medium: {
        required,
      },
    };
  },
};
</script>

<style scoped lang="stylus"></style>
