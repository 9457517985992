<template>
  <section class="article-slot gallery">
    <ArticleSlotHeader
      :slot-type="slotType"
      :slot-short-key="slotShortKey"
      :slot-index="slotIndex"
      :slot-length="article.slots?.length ?? 0"
      :component-labels="componentLabels"
      :editable="editable"
      @delete-slot="onDeleteClick"
      @shift-slot="onShiftSlotClick"
    />
    <main class="article-slot__main">
      <BaseTextInput
        v-model="title"
        :label="componentLabels?.title?.label"
        :readonly="!editable"
        class="form-field--small mt--3"
      />
      <div class="gallery__images-wrapper">
        <div
          v-for="(medium, mediumIndex) in media"
          :key="`gallery-slide-${medium.mediumId || mediumIndex}`"
          class="gallery__item"
        >
          <div v-if="editable" class="gallery__item-button-wrapper">
            <button
              class="gallery__item-button"
              :class="{ '-disabled': mediumIndex === 0 }"
              @click="onShiftMediumAtIndex(mediumIndex, 'up')"
            >
              <SvgIcon icon="arrow-up" />
            </button>
            <button
              class="gallery__item-button"
              :class="{ '-disabled': mediumIndex >= media?.length - 1 }"
              @click="onShiftMediumAtIndex(mediumIndex, 'down')"
            >
              <SvgIcon icon="arrow-down" />
            </button>
            <button
              class="gallery__item-button"
              @click="onRemoveMediumAtIndex(mediumIndex)"
            >
              <SvgIcon icon="trash-alt" />
            </button>
          </div>
          <ArticleImage
            :medium="medium"
            :disabled-medium-ids="media.map((medium) => medium.mediumId)"
            :editable="editable"
            @medium-selected="
              (val) => onGalleryMediumSelected(mediumIndex, val)
            "
          />
        </div>
      </div>
      <BaseButton
        v-if="editable"
        :text="componentLabels?.buttonText"
        icon="plus"
        class="button--inline button--black button--small mt--3"
        @click="onAddMediumClick"
      />
    </main>
  </section>
</template>
<script>
import BaseTextInput from "@/components/form/BaseTextInput.vue";
import ArticleSlotHeader from "@/components/article/ArticleSlotHeader.vue";
import articleSlotMixin from "@/mixins/articleSlotMixin";
import useVuelidate from "@vuelidate/core";
import BaseButton from "@/components/elements/BaseButton.vue";
import ArticleImage from "@/components/article/ArticleImage.vue";
import { MEDIUM_PURPOSE_TYPES } from "@/constants";
import SvgIcon from "@/components/common/SvgIcon.vue";

export default {
  name: "ArticleGallerySlot",
  components: {
    SvgIcon,
    ArticleImage,
    BaseButton,
    ArticleSlotHeader,
    BaseTextInput,
  },
  mixins: [articleSlotMixin],
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  computed: {
    title: {
      get() {
        return this.article?.slots[this.slotIndex]?.title;
      },
      set(value) {
        this.updateStore("title", value);
      },
    },
    media() {
      return this.article?.slots[this.slotIndex]?.media || [];
    },
  },
  methods: {
    onAddMediumClick() {
      const media = [
        ...this.media,
        {
          purposeType: MEDIUM_PURPOSE_TYPES.frontend,
          type: "image",
        },
      ];
      this.updateStore("media", media);
    },
    onRemoveMediumAtIndex(indexToRemove) {
      const media = [...this.media];
      media.splice(indexToRemove, 1);
      this.updateStore("media", media);
    },
    onShiftMediumAtIndex(indexToShift, dir) {
      const media = [...this.media];
      const oldMediaIndex = indexToShift;
      const newMediaIndex =
        dir === "up" ? oldMediaIndex - 1 : oldMediaIndex + 1;
      [media[oldMediaIndex], media[newMediaIndex]] = [
        media[newMediaIndex],
        media[oldMediaIndex],
      ];
      this.updateStore("media", media);
    },
    onGalleryMediumSelected(indexToUpdate, value) {
      const media = [...this.media];
      media[indexToUpdate] = value;
      this.updateStore("media", media);
    },
  },
};
</script>
<style lang="stylus" scoped>
.gallery {

  &__images-wrapper {
    margin-top 32px
    display flex
    flex-direction column
    gap 32px
  }

  &__item {
    display flex
    flex-direction column

    &-button-wrapper {
      margin-left auto
      display flex
    }

    &-button {
      cursor pointer
      width 32px
      aspect-ratio 1 / 1
      flex 0 0 auto
      align-self stretch
      display flex
      align-items center
      justify-content center
      font-size 16px
      color inherit
      transition color $duration-transition-hover-default ease-in-out

      &:hover {
        color $color-brand-green
      }
    }
  }
}
</style>
