<template>
  <section class="advertorials mt--8">
    <h1 class="headline headline--main">
      {{ labels?.advertorials?.headline }}
    </h1>
    <Filters @dropDownsActive="(val) => (lockTable = val)" />
    <table
      v-if="advertorials?.length"
      class="advertorials__table mt--4"
      :class="{ 'advertorials__table--locked': lockTable }"
    >
      <thead>
        <tr>
          <td
            v-for="column in tableColumns"
            :key="`advertorials-thead-td-${column}`"
          >
            {{ labels?.advertorials?.table?.[column] }}
            <button
              type="button"
              class="advertorials__sort-button"
              @click="onSortButtonClick(column)"
            >
              <SvgIcon :icon="getSortingIconName(column)" />
            </button>
          </td>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="advertorial in advertorials || []"
          :key="`advertorials-tr-${advertorial.id}`"
        >
          <td>
            <Status :status="advertorial.dashboardStatus" />
          </td>
          <td>
            <Link
              :url="`/advertorial/${advertorial.id}/${
                advertorial.isSelfServiceArticle ? 'article' : 'briefing'
              }`"
              :text="
                advertorial.keyword
                  ? advertorial.keyword
                  : labels?.advertorials?.hasNoKeyword
              "
              class="link--table"
            />
          </td>
          <td>{{ advertorial.imprintCompanyName }}</td>
          <td>
            {{ config.publisher?.[advertorial.publisher]?.name }}
          </td>
          <td>
            <div class="start-date">
              {{ toDateString(advertorial.startDate)
              }}<a
                v-if="advertorial.desktopLiveUrl"
                :href="advertorial.desktopLiveUrl"
              >
                <SvgIcon icon="external-link-alt" />
              </a>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <InfoTile
      v-else-if="!currentTeam?.id"
      :text="labels?.advertorials?.userHasNoTeams"
      tileType="warning"
      class="mt--4"
    />
    <InfoTile
      v-else-if="allAdvertorials.length"
      :text="labels?.advertorials?.zeroResults"
      class="mt--4"
    />
    <div v-if="dashboardStatus" class="advertorials__status-legend mt--5">
      <Status
        v-for="(statusObject, statusId) in dashboardStatus"
        :key="`status-legend-${statusId}`"
        :status="statusId"
        class="fs--s status--small"
      />
    </div>
  </section>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import advertorialsStore from "@/store/modules/advertorials";
import Status from "@/components/elements/Status";
import formatStringMixin from "@/mixins/formatStringMixin";
import Filters from "@/components/overview/Filters";
import SvgIcon from "@/components/common/SvgIcon";
import InfoTile from "@/components/overview/InfoTile";
import Link from "@/components/elements/Link";

export default {
  name: "Advertorials",
  props: {
    tableColumns: {
      type: Array,
      default() {
        return [
          "dashboardStatus",
          "keyword",
          "companyName",
          "publisher",
          "startDate",
        ];
      },
    },
  },
  mixins: [formatStringMixin],
  components: {
    InfoTile,
    SvgIcon,
    Filters,
    Link,
    Status,
  },
  data() {
    return {
      lockTable: false,
    };
  },
  computed: {
    ...mapState("common", ["config", "labels"]),
    ...mapState("advertorials", {
      activeSorting: "activeSorting",
      allAdvertorials: "advertorials",
    }),
    ...mapGetters("advertorials", {
      advertorials: "getFilteredAdvertorials",
    }),
    ...mapGetters("common", {
      dashboardStatus: "getDashboardStatus",
    }),
    ...mapGetters("user", {
      currentTeam: "getCurrentTeam",
    }),
  },
  beforeMount() {
    if (!this.$store.hasModule("advertorials")) {
      this.$store.registerModule("advertorials", advertorialsStore);
    }
    this.fetchAdvertorials(this.currentTeam?.id);
  },
  methods: {
    ...mapActions("advertorials", ["fetchAdvertorials", "setActiveSorting"]),
    onSortButtonClick(prop) {
      this.setActiveSorting({
        prop,
        direction:
          this.activeSorting?.prop === prop &&
          this.activeSorting?.direction === "asc"
            ? "desc"
            : "asc",
      });
    },
    getSortingIconName(prop) {
      let iconName = "sort";
      if (this.activeSorting?.prop === prop) {
        iconName =
          this.activeSorting.direction === "asc" ? "sort-up" : "sort-down";
      }
      return iconName;
    },
  },
};
</script>

<style lang="stylus" scoped>

.advertorials {

  table-default()

  &__status-legend {
    display flex
    gap 16px
  }

  .start-date {
    display flex
    gap 8px
    align-items center

    a {
      width 18px
    }

    svg {
      width 100%
    }
  }

  &__sort-button {
    padding 4px 8px
    font-size 0.8em
    cursor pointer

    .icon {
      display block
    }
  }
}
</style>
