<template>
  <header class="article-slot__header">
    <SvgIcon :icon="slotIcon" />
    <div>{{ componentLabels?.name }}</div>
    <Tooltip v-if="componentLabels.tooltip">{{
      componentLabels.tooltip
    }}</Tooltip>
    <div v-if="editable" class="article-slot__header-button-wrapper">
      <button
        class="article-slot__header-button"
        :class="{ '-disabled': slotIndex === 0 }"
        @click="$emit('shift-slot', { dir: 'up' })"
      >
        <SvgIcon icon="arrow-up" />
      </button>
      <button
        class="article-slot__header-button"
        :class="{ '-disabled': slotIndex >= slotLength - 1 }"
        @click="$emit('shift-slot', { dir: 'down' })"
      >
        <SvgIcon icon="arrow-down" />
      </button>
      <button class="article-slot__header-button" @click="$emit('delete-slot')">
        <SvgIcon icon="trash-alt" />
      </button>
    </div>
  </header>
</template>
<script>
import SvgIcon from "@/components/common/SvgIcon.vue";
import Tooltip from "@/components/common/Tooltip.vue";
import { SLOT_TYPE_ICONS } from "@/constants";
import { mapState } from "vuex";

export default {
  name: "ArticleSlotHeader",
  components: { Tooltip, SvgIcon },
  props: {
    componentLabels: {
      type: Object,
      default() {
        return {};
      },
    },
    editable: {
      type: Boolean,
      default: true,
    },
    slotShortKey: {
      type: String,
      default: null,
    },
    slotType: {
      type: String,
      default: null,
    },
    slotLength: {
      type: Number,
      default: 0,
    },
    slotIndex: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    ...mapState("common", ["labels"]),
    slotIcon() {
      return SLOT_TYPE_ICONS[this.slotShortKey];
    },
  },
  emits: ["delete-slot", "shift-slot"],
};
</script>
